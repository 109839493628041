import React from 'react';
import CompanyLogo from './CompanyLogo';

const tickers = ['AAPL', 'MSFT', 'GOOGL', 'AMZN']; // Add all the tickers you need for testing

const StockSite = () => {
    return (
        <div>
            <h1>Stock Site</h1>
            <div>
                {tickers.map(ticker => (
                    <div key={ticker} style={{ display: 'inline-block', margin: '10px', textAlign: 'center' }}>
                        <CompanyLogo ticker={ticker} />
                        <p>{ticker}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StockSite;


