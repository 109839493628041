import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js'; // Correct Stripe import
import config from '../../config/config'; // Adjust to match your project structure
import RegistrationAndSubscriptionForm from './RegistrationAndSubscriptionForm'; // Your form component

// Initialize Stripe with the publishable key from the config file
const stripePromise = loadStripe(config.stripePublishableKey);

const RegistrationAndSubscriptionFormWrapper = () => {
  return (
    <Elements stripe={stripePromise}>
      <RegistrationAndSubscriptionForm />
    </Elements>
  );
};

export default RegistrationAndSubscriptionFormWrapper;
//