import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import config from '../../config/config';

const CheckoutForm = ({ cartItems }) => {
  const { apiStagingUrl } = config;

  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.log("Stripe.js hasn't loaded yet.");
      setError("Please try again in a moment.");
      return;
    }

    setProcessing(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      console.error('Stripe error:', error);
      setError(error.message);
      setProcessing(false);
    } else {
      try {
        const { id } = paymentMethod;
        const response = await axios.post(`${apiStagingUrl}/create-checkout-session/`, {
          paymentMethodId: id,
          cartItems,
        });
        console.log('Server response:', response.data);
        if (response.data.success) {
          window.location.href = '/success';
        } else {
          setError('Payment failed. Please try again.');
          setProcessing(false);
        }
      } catch (error) {
        console.error('Error:', error);
        setError('An error occurred. Please try again.');
        setProcessing(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <h2 className="text-xl font-bold mb-4">Stripe Checkout</h2>
      <div className="mb-4">
        <CardElement className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
      </div>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <button
        type="submit"
        disabled={!stripe || !elements || processing}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        {processing ? 'Processing...' : 'Pay'}
      </button>
    </form>
  );
};

export default CheckoutForm;
