// src/components/StripeAPI2/PricingTableAPI2.js
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config/config';

const PricingTableAPI2 = () => {
  const navigate = useNavigate();
  const [pricingPlans, setPricingPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch plans from the API
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(`${config.apiStagingUrl}/api/get-plans/`);
        const filteredPlans = response.data.plans.filter((plan) => plan.id === 5 || plan.id === 6);
        setPricingPlans(filteredPlans);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching pricing plans:', err);
        setError('Failed to load pricing plans.');
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  const handleSubscribe = (planId) => {
    navigate('/registerapi2', { state: { selectedPlanId: planId } });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container mx-auto px-4">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-center my-8"
      >
        <h1 className="text-4xl font-bold mb-4">Market Catalysts Pricing</h1>
        <p className="text-lg text-gray-700 max-w-2xl mx-auto">
          Choose the plan that best fits your needs and join our community of savvy investors.
          Get access to comprehensive market insights, stock portfolios, and essential calendars.
        </p>
      </motion.div>
      <motion.h2
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-2xl font-bold mb-4 text-center"
      >
        Choose a Subscription Plan
      </motion.h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {pricingPlans.map((plan, index) => (
          <motion.div
            key={plan.id}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: index * 0.2 }}
            className="bg-white rounded-lg shadow-md p-6"
          >
            <h3 className="text-xl font-bold mb-4">{plan.name}</h3>
            {/* Safely handle price as a number */}
            <div className="text-4xl font-bold mb-4">${parseFloat(plan.price).toFixed(2)}</div>
            <p className="mb-6">{plan.description}</p>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
              onClick={() => handleSubscribe(plan.id)}
            >
              Subscribe
            </button>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default PricingTableAPI2;
//