// ConfirmationPage.js

import React from 'react';

const ConfirmationPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full">
        <h1 className="text-3xl font-bold text-green-600 mb-4 text-center">
          Thank You for Subscribing!
        </h1>
        <p className="text-gray-700 text-lg mb-6 text-center">
          Your subscription is now active.
        </p>
        <p className="text-gray-600 mb-8 text-center">
          You can now access all the premium features. If you have any questions,
          feel free to contact our support team.
        </p>
        <button
          onClick={() => window.location.href = '/profile'}
          className="w-full bg-green-500 hover:bg-green-600 text-white font-semibold py-3 rounded-md transition duration-200"
        >
          Go to Dashboard
        </button>
      </div>
    </div>
  );
};

export default ConfirmationPage;
