// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config/config';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { apiStagingUrl } = config;
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          // Make a request to your backend to retrieve the user data
          const response = await axios.get(`${apiStagingUrl}/usermanagement/profile/`, {
            headers: { Authorization: `Token ${token}` },
          });
          setUser(response.data);
          setIsAuthenticated(true);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error checking authentication:', error);
        setIsLoading(false);
      }
    };
    checkAuth();
  }, [apiStagingUrl]);

  const login = async (username, password) => {
    try {
      // Make a request to your backend to authenticate the user
      const response = await axios.post(`${apiStagingUrl}/auth/token/login/`, { username, password });
      localStorage.setItem('token', response.data.auth_token);
      setUser(response.data.user);
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Error logging in:', error);
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
    setIsAuthenticated(false);
  };

  const value = {
    user,
    isAuthenticated,
    isLoading,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};