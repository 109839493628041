import { motion } from 'framer-motion';

const TestimonialsSection = () => {
  const testimonials = [
    {
      content: 'Market Catalysts has been a game-changer for my trading. The Catalyst Calendar feature has helped me stay ahead of the markets and make more profitable trades.',
      author: 'Drew T',
      role: 'License Professional Trader',
    },
    {
      content: 'I have been using Market Catalysts for the past few months, and I am impressed with the depth and accuracy of their data. It has become an essential tool in my trading arsenal.',
      author: 'Amelia K',
      role: 'Hedge Fund Manager',
    },
  ];

  return (
    <section className="bg-gray-100 py-20">
      <div className="container mx-auto px-4">
        <motion.h2 
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-3xl md:text-4xl font-bold mb-12 text-center"
        >
          What Our Users Say
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {testimonials.map((testimonial, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, delay: index * 0.2 }}
              className="bg-white shadow-lg rounded-lg p-6"
            >
              <p className="text-gray-800 mb-4">{testimonial.content}</p>
              <p className="font-bold">{testimonial.author}</p>
              <p className="text-gray-600">{testimonial.role}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;