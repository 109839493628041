import React from 'react';

const ErrorPage = () => {
  return (
    <div className="error-container">
      <h1>Something went wrong</h1>
      <p>Please try again or contact support for help.</p>
      <a href="/contact-support" className="btn-primary">Contact Support</a>
    </div>
  );
};

export default ErrorPage;
//