import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { subscribeUser } from "@strapi-newsletter/react";
import { Helmet } from 'react-helmet';
import config from '../../config/config';

const RegistrationForm = () => {
  const { apiStagingUrl } = config;

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [subscribe, setSubscribe] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage('');
    setErrorMessage('');

    try {
      const response = await axios.post(`${apiStagingUrl}/auth/users/`, {
        username,
        email,
        password,
      });

      if (response.status === 201) {
        const userId = response.data.id;

        if (subscribe) {
          try {
            await subscribeUser(email, "https://victorious-bird-78d1c6c5c3.strapiapp.com");
          } catch (error) {
            console.error('Error subscribing to newsletter:', error);
          }
        }

        setSuccessMessage('Registration successful! Redirecting to subscription page...');
        setTimeout(() => {
          navigate(`/subscribe?clientReferenceId=${userId}`);
        }, 2000);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrorMessage(Object.values(error.response.data).join(' ') || 'An error occurred during registration. Please try again.');
      } else {
        setErrorMessage('An unexpected error occurred. Please try again later.');
      }
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Register - Market Catalysts",
    "url": "https://www.marketcatalysts.com/register",
    "description": "Register to Market Catalysts to access comprehensive market analysis and insights. Subscribe to our newsletter for the latest updates.",
    "publisher": {
      "@type": "Organization",
      "name": "Market Catalysts",
      "logo": {
        "@type": "ImageObject",
        "url": "https://www.marketcatalysts.com/logo.png"
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Register - Market Catalysts</title>
        <meta name="description" content="Register to Market Catalysts to access comprehensive market analysis and insights. Subscribe to our newsletter for the latest updates." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/mc.png" />
        <link rel="canonical" href="https://www.marketcatalysts.com/register" />
        <meta name="keywords" content="register, sign up, market catalysts, stock market analysis, financial insights, market analysis, newsletter subscription" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.marketcatalysts.com/register" />
        <meta property="og:title" content="Register - Market Catalysts" />
        <meta property="og:description" content="Register to Market Catalysts to access comprehensive market analysis and insights. Subscribe to our newsletter for the latest updates." />
        <meta property="og:image" content="https://www.marketcatalysts.com/register-og.jpg" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.marketcatalysts.com/register" />
        <meta property="twitter:title" content="Register - Market Catalysts" />
        <meta property="twitter:description" content="Register to Market Catalysts to access comprehensive market analysis and insights. Subscribe to our newsletter for the latest updates." />
        <meta property="twitter:image" content="https://www.marketcatalysts.com/register-twitter.jpg" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="container mx-auto px-4">
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Username
            </label>
            <input
              id="username"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mt-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              id="email"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mt-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <input
              id="password"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label>
              <input
                type="checkbox"
                checked={subscribe}
                onChange={(e) => setSubscribe(e.target.checked)}
                className="mr-2 leading-tight"
              />
              Subscribe to the newsletter
            </label>
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Continue
            </button>
            <Link to="/login" className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800">
              Already a user? Log in here
            </Link>
          </div>
          {successMessage && <p className="text-green-500 text-center">{successMessage}</p>}
          {errorMessage && <p className="text-red-500 text-center">{errorMessage}</p>}
          <div className="text-center mt-4">
            By clicking 'Continue' you agree to our{' '}
            <Link to="/terms-of-use" className="text-blue-500 hover:text-blue-800">
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link to="/privacy-policy" className="text-blue-500 hover:text-blue-800">
              Privacy Statement
            </Link>
            .
          </div>
        </form>
      </div>
    </>
  );
};

export default RegistrationForm;

