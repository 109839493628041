const config = {
  // apiStagingUrl: 'https://marketcatalystsstaging2.azurewebsites.net',
  // stripePublishableKey: 'pk_test_51OWoegK7imM49pkJaWb7HQwErr8WKynzV07lgkHUQkvWabdIUdnVJwflATc5QlTJJxZdEgx6irxlomQCZVlNAswE00Sc47syj5',
  // stripePricingTableId: 'prctbl_1OxCwaK7imM49pkJ4NdTSbPW',
  // stripeCustomerPortalUrl: 'https://billing.stripe.com/p/login/test_cN2bLD9FQ0iQ7Xq288',

  // Pricing plans for staging
  // pricingPlans: [
  //   {
  //     id: 4,
  //     name: 'Market Catalysts - Monthly',
  //     price: 20.00,
  //     description: 'Access to Catalysts Calendars every 4 weeks.',
  //     stripe_price_id: 'price_1Ox3MrK7imM49pkJ1uUkpcBV',
  //   },
  //   {
  //     id: 3,
  //     name: 'Market Catalysts - Semiannual',
  //     price: 110.00,
  //     description: 'Access to Catalysts Calendars every 6 months.',
  //     stripe_price_id: 'price_1Ox3NGK7imM49pkJVzWUlIY9',
  //   },
  //   {
  //     id: 2,
  //     name: 'Market Catalysts - Annual',
  //     price: 199.00,
  //     description: 'Access to Catalysts Calendars on an annual basis.',
  //     stripe_price_id: 'price_1Ox3NyK7imM49pkJO60efjFG',
  //   }
  // ],

  // Live environment values (uncomment for production):
  apiStagingUrl: 'https://marketcatalystsproduction.azurewebsites.net',
  stripePublishableKey: 'pk_live_51OWoegK7imM49pkJ235wWAoX5EHcMtj5e4wKD57VmaybgNLZDjufPGmleXpvZ98C2lDVDOG1VcDdGTevgOREsSoB00n0ETuNwz',
  stripePricingTableId: 'prctbl_1Ox3enK7imM49pkJVOj9Ddeu',
  stripeCustomerPortalUrl: 'https://billing.stripe.com/p/login/aEU16H1Wf0cB1ZS5kk',

  // Live pricing plans
  pricingPlans: [
    {
      id: 4,
      name: 'Market Catalysts - Monthly',
      price: 20.00,
      description: 'Access to Catalysts Calendars every 4 weeks.',
      stripe_price_id: 'price_1Ox3MrK7imM49pkJ1uUkpcBV',
    },
    {
      id: 3,
      name: 'Market Catalysts - Semiannual',
      price: 110.00,
      description: 'Access to Catalysts Calendars every 6 months.',
      stripe_price_id: 'price_1Ox3NGK7imM49pkJVzWUlIY9',
    },
    {
      id: 2,
      name: 'Market Catalysts - Annual',
      price: 199.00,
      description: 'Access to Catalysts Calendars on an annual basis.',
      stripe_price_id: 'price_1Ox3NyK7imM49pkJO60efjFG',
    }
  ],

  // Other environment-specific values
};

export default config;
