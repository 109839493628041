// Header.js

import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import './Header.css';
import config from '../config/config';

function Header() {
  const { apiStagingUrl } = config;
  const { isAuthenticated, logout } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  let navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    const formattedTicker = searchTerm.toUpperCase();
    navigate(`/company/${formattedTicker}`);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="site-header">
      <div className="logo">
        <img
          src={`${apiStagingUrl}/static/mc_logos/mcccccc.png`}
          alt="MarketCatalysts"
          className="logo-img"
        />
      </div>
      <button className="menu-toggle" onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </button>
      <div className={`header-content ${menuOpen ? 'open' : ''}`}>
        <form onSubmit={handleSearch} className="search-container">
          <input
            className="search-box"
            type="text"
            placeholder="Search symbols"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button type="submit" className="search-icon">
            <i className="fa fa-search"></i>
          </button>
        </form>
        <ul className="main-menu">
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/invest/portfolios">Portfolios</a>
          </li>
          <li>
            <a href="/team">Team</a>
          </li>
          <li>
            <a href="/about-us">About</a>
          </li>
          <li>
            <a href="/pricing">Pricing</a>
          </li>
        </ul>
        <ul className="secondary-menu">
          {isAuthenticated ? (
            <>
              <li>
                <a href="/profile" className="profile-button">
                  Profile
                </a>
              </li>
              <li>
                <a href="/subscription" className="subscribe-button">
                  Subscribe
                </a>
              </li>
              <li>
                <button onClick={logout} className="logout-button">
                  Logout
                </button>
              </li>
            </>
          ) : (
            <div className="auth-buttons">
              <li>
                <a href="/login">Login</a>
              </li>
              <li>
                <button
                  className="get-started-btn"
                  onClick={() => navigate('/register')}
                >
                  Get Started
                </button>
              </li>
            </div>
          )}
        </ul>
      </div>
    </nav>
  );
}

export default Header;
