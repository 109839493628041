import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Restricted = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Register - Market Catalysts",
    "url": "https://www.marketcatalysts.com/register",
    "description": "Register to Market Catalysts to access comprehensive market analysis and insights. Subscribe to our newsletter for the latest updates.",
    "publisher": {
      "@type": "Organization",
      "name": "Market Catalysts",
      "logo": {
        "@type": "ImageObject",
        "url": "https://www.marketcatalysts.com/mc.png"
      }
    },
    "potentialAction": [
      {
        "@type": "RegisterAction",
        "name": "Create Account",
        "target": "https://www.marketcatalysts.com/register"
      },
      {
        "@type": "SubscribeAction",
        "name": "Subscribe to Newsletter",
        "target": "https://www.marketcatalysts.com/subscribe"
      }
    ],
    "keywords": "register, sign up, create account, market analysis, stock insights, financial news, newsletter subscription",
    "inLanguage": "en-US",
    "datePublished": "2024-08-01",
    "dateModified": "2024-08-01"
  };

  return (
    <>
      <Helmet>
        <title>Access Restricted - Market Catalysts</title>
        <meta name="description" content="This content is only available to registered users. Please sign in or sign up to access this feature." />
        <meta name="keywords" content="access restricted, login required, sign up, market catalysts, stock market analysis, financial insights" />
        <link rel="canonical" href="https://www.marketcatalysts.com/restricted" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.marketcatalysts.com/restricted" />
        <meta property="og:title" content="Access Restricted - Market Catalysts" />
        <meta property="og:description" content="This content is only available to registered users. Please sign in or sign up to access this feature." />
        <meta property="og:image" content="https://www.marketcatalysts.com/restricted-og.jpg" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.marketcatalysts.com/restricted" />
        <meta property="twitter:title" content="Access Restricted - Market Catalysts" />
        <meta property="twitter:description" content="This content is only available to registered users. Please sign in or sign up to access this feature." />
        <meta property="twitter:image" content="https://www.marketcatalysts.com/restricted-twitter.jpg" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="min-h-screen flex flex-col items-center bg-gray-100">
        <header className="bg-white p-8 rounded shadow-md text-center max-w-md mx-auto mb-6">
          <h1 className="text-4xl font-bold mb-2">Market Catalyst</h1>
          <p className="text-xl">Providing comprehensive market analysis and insights.</p>
        </header>
        <div className="bg-white p-8 rounded shadow-md text-center max-w-md mx-auto">
          <h1 className="text-4xl font-bold mb-4">Access Restricted</h1>
          <p className="text-xl mb-8">
            This content is only available to registered users. Please sign in or sign up to access this feature.
          </p>
          <div className="bg-blue-100 p-4 rounded mb-4">
            <h2 className="text-2xl font-semibold mb-2">Catalyst Calendars</h2>
            <p className="text-lg mb-2">
              Stay informed with our comprehensive catalyst calendars, including Earnings, FDA, Conference, Economic, IPO, Dividend, and Splits Calendars. 
            </p>
            <p className="text-lg mb-4">
              Track important market events and never miss a beat.
            </p>
          </div>
          <div className="bg-green-100 p-4 rounded mb-8">
            <h2 className="text-2xl font-semibold mb-2">Portfolios</h2>
            <p className="text-lg mb-2">
              Discover our expertly curated portfolios, including the Market Catalyst 110 and sector-specific portfolios. 
            </p>
            <p className="text-lg mb-4">
              Gain insights into top-performing stocks across different sectors.
            </p>
          </div>
          <div className="flex justify-center space-x-4">
            <Link
              to="/login"
              className="bg-blue-500 text-white px-6 py-3 rounded font-semibold hover:bg-blue-600 transition duration-200"
            >
              Sign In
            </Link>
            <Link
              to="/register"
              className="bg-gray-200 text-gray-700 px-6 py-3 rounded font-semibold hover:bg-gray-300 transition duration-200"
            >
              Sign Up
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Restricted;


