import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import './SubscriptionForm.css'; // You can reuse the same CSS or create a new one
import { useNavigate } from 'react-router-dom';
import config from '../../config/config'; // Import config for API URL

const SubscriptionForm = () => {
  const navigate = useNavigate();
  const [promotionCode, setPromotionCode] = useState('');
  const [selectedPlanId, setSelectedPlanId] = useState(null); // For plan selection
  const [pricingPlans, setPricingPlans] = useState([]); // Pricing plans state
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  // Fetch pricing plans from API and filter for plan ID 5 and 6
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${config.apiStagingUrl}/api/get-plans/`, {
          headers: { Authorization: `Token ${token}` }, // Send token in case plans are protected
        });
        const filteredPlans = response.data.plans.filter(plan => plan.id === 5 || plan.id === 6);
        setPricingPlans(filteredPlans);

        // Pre-select a plan if desired
        if (filteredPlans.length > 0) {
          setSelectedPlanId(filteredPlans[0].id);
        }
        console.log('Pricing plans loaded:', filteredPlans);
      } catch (err) {
        console.error('Error fetching pricing plans:', err);
        setError('Failed to load pricing plans.');
      }
    };
    fetchPlans();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    if (!stripe || !elements) {
      setError('Stripe.js has not loaded.');
      setLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('You must be logged in to subscribe.');
        setLoading(false);
        navigate('/login');
        return;
      }

      // Create the payment method
      const cardElement = elements.getElement(CardElement);
      console.log('Creating payment method...');
      const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (paymentMethodError) {
        setError(paymentMethodError.message);
        console.error('Payment method error:', paymentMethodError.message);
        setLoading(false);
        return;
      }

      console.log('Payment method created successfully:', paymentMethod.id);

      // Look up the stripe_price_id based on the selected plan ID
      const selectedPlan = pricingPlans.find(plan => plan.id === parseInt(selectedPlanId));
      const stripePriceId = selectedPlan ? selectedPlan.stripe_price_id : null;

      if (!stripePriceId) {
        setError('Selected plan does not have an associated Stripe price ID.');
        setLoading(false);
        return;
      }

      // Create the subscription
      console.log('Creating subscription...');
      const subscriptionResponse = await axios.post(
        `${config.apiStagingUrl}/api/create-subscription/`,
        {
          payment_method_id: paymentMethod.id,
          price_id: stripePriceId,
          promotion_code: promotionCode,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Subscription response data:', subscriptionResponse.data);

      const { subscription, client_secret } = subscriptionResponse.data;
      console.log('Subscription created:', subscription.id);

      if (subscription.status === 'active' || subscription.status === 'trialing') {
        console.log('Subscription is active or in trial. Redirecting to confirmation...');
        navigate('/confirmation');
      } else if (subscription.status === 'incomplete') {
        console.log('Subscription incomplete, handling 3D Secure...');
        const { error: confirmError } = await stripe.confirmCardPayment(client_secret);
        if (confirmError) {
          setError(`Payment failed: ${confirmError.message}`);
          console.error('Payment confirmation error:', confirmError.message);
          setLoading(false);
          return;
        } else {
          console.log('Payment succeeded. Redirecting to confirmation...');
          navigate('/confirmation');
        }
      } else {
        console.error('Subscription status is unknown:', subscription.status);
        setError('Subscription status is unknown.');
        setLoading(false);
      }
    } catch (err) {
      console.error('Error during subscription creation:', err);
      if (err.response) {
        setError(
          err.response.data.error ||
            err.response.data.non_field_errors?.[0] ||
            err.response.data.detail ||
            'An error occurred during subscription.'
        );
      } else if (err.request) {
        setError('No response from server. Please try again later.');
      } else {
        setError('An unexpected error occurred.');
      }
      setLoading(false);
    }
  };

  return (
    <div className="form-container">
      <h2 className="form-title">Subscribe</h2>
      {error && <div className="error-message">{error}</div>}
      <form onSubmit={handleSubmit} className="styled-form">
        {/* Pricing Plan Selection */}
        <h3 className="section-title">Choose Your Plan</h3>
        <div className="form-group">
          <select
            value={selectedPlanId}
            onChange={(e) => setSelectedPlanId(e.target.value)}
            required
            className="input"
          >
            <option value="">Select Plan</option>
            {pricingPlans.map((plan) => (
              <option key={plan.id} value={plan.id}>
                {plan.name} - ${parseFloat(plan.price).toFixed(2)}
                {plan.id === 6 && ' ★ Most Popular'}
              </option>
            ))}
          </select>
        </div>

        {/* Product Description Section */}
        <div className="product-description">
          <h4>What's included in your subscription:</h4>
          <ul>
            <li>Market Catalyst Calendar</li>
            <li>Stock Portfolios</li>
            <li>Earnings Calendars</li>
            <li>FDA Calendar</li>
            {/* Add more items as needed */}
          </ul>
          <p className="trial-note">Includes a 7-day free trial if eligible</p>
        </div>

        {/* Promotion Code */}
        <div className="form-group">
          <label htmlFor="promotionCode" className="label">
            Promotion Code
          </label>
          <input
            id="promotionCode"
            type="text"
            placeholder="Enter promotion code"
            value={promotionCode}
            onChange={(e) => setPromotionCode(e.target.value)}
            className="input"
          />
        </div>

        {/* Payment Details */}
        <h3 className="section-title">Your Payment Details</h3>
        <div className="card-element-container">
          <CardElement options={{ style: { base: { fontSize: '16px' } } }} />
        </div>

        <button
          type="submit"
          className="submit-button"
          disabled={!stripe || loading}
        >
          {loading ? 'Processing...' : 'Subscribe'}
        </button>
      </form>
    </div>
  );
};

export default SubscriptionForm;
