// PricingTable.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../config/config';

const PricingTable = ({ clientReferenceId }) => {
  const { stripePublishableKey, stripePricingTableId } = config;
  const navigate = useNavigate();

  const handlePlanSelect = (priceId) => {
    navigate('/subscribe/payment', { state: { priceId, clientReferenceId } });
  };

  return (
    <div>
      <h2>Choose a Subscription Plan</h2>
      <stripe-pricing-table
        pricing-table-id={stripePricingTableId}
        publishable-key={stripePublishableKey}
        client-reference-id={clientReferenceId}
        onselect={handlePlanSelect}
      />
    </div>
  );
};

export default PricingTable;