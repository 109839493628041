import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PricingTable from './PricingTable';

const SubscriptionPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const clientReferenceId = searchParams.get('clientReferenceId');

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Subscription Plans - Market Catalysts",
    "url": "https://www.marketcatalysts.com/subscription",
    "description": "Choose from our flexible subscription plans and get access to Market Catalysts' comprehensive market analysis and insights.",
    "publisher": {
      "@type": "Organization",
      "name": "Market Catalysts",
      "logo": {
        "@type": "ImageObject",
        "url": "https://www.marketcatalysts.com/mc.png"
      }
    },
    "potentialAction": {
      "@type": "SubscribeAction",
      "name": "Subscribe",
      "target": "https://www.marketcatalysts.com/subscribe"
    },
    "keywords": "subscription plans, market analysis, financial insights, stock market analysis, premium subscription",
    "inLanguage": "en-US",
    "datePublished": "2024-08-01",
    "dateModified": "2024-08-01"
  };

  return (
    <div className="container mx-auto">
      <Helmet>
        <html lang="en" />
        <title>Subscription Plans - Market Catalysts</title>
        <meta name="description" content="Choose from our flexible subscription plans and get access to Market Catalysts' comprehensive market analysis and insights." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/mc.png" />
        <link rel="canonical" href="https://www.marketcatalysts.com/subscription" />
        <meta name="keywords" content="subscription plans, market analysis, stock market insights, financial planning, investment strategies" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.marketcatalysts.com/subscription" />
        <meta property="og:title" content="Subscription Plans - Market Catalysts" />
        <meta property="og:description" content="Choose from our flexible subscription plans and get access to Market Catalysts' comprehensive market analysis and insights." />
        <meta property="og:image" content="https://www.marketcatalysts.com/subscription-og.jpg" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.marketcatalysts.com/subscription" />
        <meta property="twitter:title" content="Subscription Plans - Market Catalysts" />
        <meta property="twitter:description" content="Choose from our flexible subscription plans and get access to Market Catalysts' comprehensive market analysis and insights." />
        <meta property="twitter:image" content="https://www.marketcatalysts.com/subscription-twitter.jpg" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <h1 className="text-2xl font-bold mb-4">Select a Subscription Plan</h1>
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <PricingTable clientReferenceId={clientReferenceId} />
      </div>
    </div>
  );
};

export default SubscriptionPage;
