import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const PricingTableAPI = () => {
  const navigate = useNavigate();

  const pricingPlans = [
    {
      id: 4,
      name: 'Market Catalysts - Monthly',
      price: 20.00,
      description: 'Access to Catalysts Calendars every 4 weeks.',
      stripe_price_id: 'price_1OxCmDK7imM49pkJJ5I8NJD3'
    },
    {
      id: 3,
      name: 'Market Catalysts - Semiannual',
      price: 110.00,
      description: 'Access to Catalysts Calendars every 6 months.',
      stripe_price_id: 'price_1OxCmqK7imM49pkJI6yjwnjD'
    },
    {
      id: 2,
      name: 'Market Catalysts - Annual',
      price: 199.00,
      description: 'Access to Catalysts Calendars on an annual basis.',
      stripe_price_id: 'price_1OxCn4K7imM49pkJH7eVzfoG'
    }
  ];

  const handleGetStarted = (plan) => {
    navigate('/registerapi', { state: { selectedPlan: plan } });
  };

  return (
    <div className="container mx-auto px-4">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-center my-8"
      >
        <h1 className="text-4xl font-bold mb-4">Market Catalysts Pricing</h1>
        <p className="text-lg text-gray-700 max-w-2xl mx-auto">
          Choose the plan that best fits your needs and join our community of savvy investors. 
          Get access to comprehensive market insights, stock portfolios, and essential calendars.
        </p>
      </motion.div>
      <motion.h2 
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-2xl font-bold mb-4 text-center"
      >
        Choose a Subscription Plan
      </motion.h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {pricingPlans.map((plan, index) => (
          <motion.div
            key={plan.id}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: index * 0.2 }}
            className="bg-white rounded-lg shadow-md p-6"
          >
            <h3 className="text-xl font-bold mb-4">{plan.name}</h3>
            <div className="text-4xl font-bold mb-4">${plan.price.toFixed(2)}</div>
            <p className="mb-6">{plan.description}</p>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
              onClick={() => handleGetStarted(plan)}
            >
              Subscribe
            </button>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default PricingTableAPI;
