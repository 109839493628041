import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import config from '../config/config';

const tickers = [
  'TSLA', 'AAPL', 'C', 'ICE', 'SPGI', 'RY', 'MS', 'ALNY', 'VRSK', 'BIDU',
  'GOOGL', 'META', 'RDY', 'HEPS', 'TJX', 'BAC', 'LLY', 'NVS', 'ARGX', 'UTHR',
  'SMFG', 'MUFG', 'MCO', 'MSFT', 'NVDA', 'TSM', 'ADBE', 'AMZN', 'RBLX', 'ISRG',
  'JPM', 'WMT', 'COST', 'PEG', 'BKNG', 'JMIA', 'LNG', 'NEM', 'AEM', 'KGC',
  'RSG', 'WM', 'NFLX', 'ABNB', 'CURV', 'RELX', 'WCN', 'ECL', 'RGLD', 'PAAS',
  'KMI', 'OKE', 'WMB', 'ET', 'ORCL', 'AMGN', 'VRTX', 'CHTR', 'BIIB', 'CTAS',
  'WDS', 'FCX', 'SAP', 'HON', 'HEI', 'MPLX', 'SCCO', 'BAH', 'GE', 'SPOT',
  'PINS', 'CRM', 'CL', 'COKE', 'BG', 'BRFS', 'GOLD', 'DUK', 'FTS', 'DASH',
  'GME', 'PDD', 'SFM', 'CEG', 'ELPC', 'EBR', 'LNT', 'IRM', 'DLR', 'ESS', 'EGP',
  'NRG', 'AVB', 'UDR', 'SO', 'NEE', 'CHD', 'BHP', 'SHEL', 'EA', 'INTU', 'NOW',
  'FRPT', 'DOC', 'EQIX', 'O', 'CPT', 'BRBR', 'TTE', 'BP'
];

const ranks = {
  'TSLA': 1, 'AAPL': 2, 'C': 3, 'ICE': 4, 'SPGI': 5, 'RY': 6, 'MS': 7, 'ALNY': 8, 'VRSK': 9, 'BIDU': 10,
  'GOOGL': 11, 'META': 12, 'RDY': 13, 'HEPS': 14, 'TJX': 15, 'BAC': 16, 'LLY': 17, 'NVS': 18, 'ARGX': 19, 'UTHR': 20,
  'SMFG': 21, 'MUFG': 22, 'MCO': 23, 'MSFT': 24, 'NVDA': 25, 'TSM': 26, 'ADBE': 27, 'AMZN': 28, 'RBLX': 29, 'ISRG': 30,
  'JPM': 31, 'WMT': 32, 'COST': 33, 'PEG': 34, 'BKNG': 35, 'JMIA': 36, 'LNG': 37, 'NEM': 38, 'AEM': 39, 'KGC': 40,
  'RSG': 41, 'WM': 42, 'NFLX': 43, 'ABNB': 44, 'CURV': 45, 'RELX': 46, 'WCN': 47, 'ECL': 48, 'RGLD': 49, 'PAAS': 50,
  'KMI': 51, 'OKE': 52, 'WMB': 53, 'ET': 54, 'ORCL': 55, 'AMGN': 56, 'VRTX': 57, 'CHTR': 58, 'BIIB': 59, 'CTAS': 60,
  'WDS': 61, 'FCX': 62, 'SAP': 63, 'HON': 64, 'HEI': 65, 'MPLX': 66, 'SCCO': 67, 'BAH': 68, 'GE': 69, 'SPOT': 70,
  'PINS': 71, 'CRM': 72, 'CL': 73, 'COKE': 74, 'BG': 75, 'BRFS': 76, 'GOLD': 77, 'DUK': 78, 'FTS': 79, 'DASH': 80,
  'GME': 81, 'PDD': 82, 'SFM': 83, 'CEG': 84, 'ELPC': 85, 'EBR': 86, 'LNT': 87, 'IRM': 88, 'DLR': 89, 'ESS': 90, 'EGP': 91,
  'NRG': 92, 'AVB': 93, 'UDR': 94, 'SO': 95, 'NEE': 96, 'CHD': 97, 'BHP': 98, 'SHEL': 99, 'EA': 100, 'INTU': 101, 'NOW': 102,
  'FRPT': 103, 'DOC': 104, 'EQIX': 105, 'O': 106, 'CPT': 107, 'BRBR': 108, 'TTE': 109, 'BP': 110
};

const TickerItem = ({ stock, rank }) => {
  const isPositive = parseFloat(stock.change) >= 0;
  const changeColor = isPositive ? 'text-green-500' : 'text-red-500';
  const changeSymbol = isPositive ? '+' : '';

  return (
    <Link to={`/company/${stock.symbol}`} className="ticker-item flex items-center px-3 py-1 bg-gray-800 border border-gray-700 rounded mx-1 hover:bg-gray-700 transition-colors duration-200 whitespace-nowrap">
      <div className="flex items-center space-x-2">
        <span className="ticker-rank text-sm font-bold text-yellow-400">#{rank}</span>
        <span className="ticker-symbol font-bold text-blue-500 text-base">{stock.symbol}</span>
        <span className="ticker-name font-semibold text-white text-sm">
          ({stock.name.length > 20 ? `${stock.name.substring(0, 20)}...` : stock.name})
        </span>
        <span className="ticker-price font-semibold text-white text-base">${parseFloat(stock.last).toFixed(2)}</span>
        <span className={`ticker-change ${changeColor} text-sm whitespace-nowrap`}>
          {changeSymbol}${Math.abs(parseFloat(stock.change)).toFixed(2)} ({changeSymbol}{parseFloat(stock.change_percent).toFixed(2)}%)
        </span>
      </div>
    </Link>
  );
};

const MarketCatalystAd = () => (
  <Link to="/invest/portfolios/market-catalysts-flagship-stocks" className="market-catalyst-ad flex items-center justify-center px-3 py-1 bg-blue-600 text-white text-sm font-semibold rounded mx-1 hover:bg-blue-700 transition-colors duration-200 whitespace-nowrap border border-white">
    Market Catalysts Portfolio
  </Link>
);

const CustomTickerTape = () => {
  const { apiStagingUrl } = config;
  const [stockData, setStockData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const tickerRef = useRef(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    const fetchStockData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiStagingUrl}/api/benzingadelayedquotes/`);
        const currentQuotes = response.data.filter(stock => tickers.includes(stock.symbol));
        setStockData(currentQuotes);
      } catch (error) {
        console.error('Error fetching stock data:', error);
        setError('Failed to fetch data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchStockData();
    const intervalId = setInterval(fetchStockData, 300000);
    return () => clearInterval(intervalId);
  }, [apiStagingUrl]);

  useEffect(() => {
    const scrollTicker = () => {
      if (tickerRef.current) {
        tickerRef.current.scrollLeft += 1; // Adjust the scroll speed as needed
        if (tickerRef.current.scrollLeft >= tickerRef.current.scrollWidth / 2) {
          tickerRef.current.scrollLeft = 0;
        }
      }
    };

    const startScrolling = () => {
      intervalRef.current = setInterval(scrollTicker, 20); // Adjust the interval as needed
    };

    const stopScrolling = () => {
      clearInterval(intervalRef.current);
    };

    if (tickerRef.current) {
      const tickerElement = tickerRef.current;
      tickerElement.addEventListener('mouseenter', stopScrolling);
      tickerElement.addEventListener('mouseleave', startScrolling);
      startScrolling();
      return () => {
        tickerElement.removeEventListener('mouseenter', stopScrolling);
        tickerElement.removeEventListener('mouseleave', startScrolling);
        clearInterval(intervalRef.current);
      };
    }
  }, [stockData]);

  if (loading) {
    return <div className="ticker-tape-wrapper bg-gray-900 py-1"><div className="ticker-tape-container w-full h-16 overflow-hidden bg-gray-900 text-white flex items-center">Loading...</div></div>;
  }

  if (error) {
    return <div className="ticker-tape-wrapper bg-gray-900 py-1"><div className="ticker-tape-container w-full h-16 overflow-hidden bg-gray-900 text-white flex items-center">Error: {error}</div></div>;
  }

  const renderTickerContent = () => {
    const content = [];
    for (let i = 0; i < stockData.length; i++) {
      const stock = stockData[i];
      const rank = ranks[stock.symbol] || (i + 1);

      content.push(
        <TickerItem key={`ticker-${i}`} stock={stock} rank={rank} />
      );

      if ((i + 1) % 5 === 0) {
        content.push(
          <MarketCatalystAd key={`ad-${i}`} />
        );
      }
    }
    return content;
  };

  return (
    <div className="ticker-tape-wrapper bg-gray-900 py-1 mt-0">
      <div className="ticker-tape-container w-full h-10 overflow-hidden bg-gray-900 whitespace-nowrap border-t border-b border-gray-900" ref={tickerRef}>
        <div className="ticker-tape-content flex items-center">
          {renderTickerContent()}
          {renderTickerContent()} {/* Duplicate content for seamless looping */}
        </div>
      </div>
      <style jsx>{`
        .ticker-tape-wrapper {
          background-color: #1a1a1a;
          padding: 10px 0;
          margin-top: 0;
        }

        .ticker-tape-container {
          width: 100%;
          height: auto;
          overflow: hidden;
          background-color: #1a1a1a;
          border-top: 1px solid #2a2a2a;
          border-bottom: 1px solid #2a2a2a;
          display: flex;
          align-items: center;
        }

        .ticker-item {
          flex: none;
          display: flex;
          align-items: center;
          padding: 10px 15px;
          background-color: #333;
          border: 1px solid #444;
          border-radius: 5px;
          margin: 0 5px;
          color: white;
          text-decoration: none;
          transition: background-color 0.3s ease;
        }

        .ticker-item:hover {
          background-color: #444;
        }

        .market-catalyst-ad {
          flex: none;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 15px;
          background-color: #3366FF; /* Adjusted to a brighter blue */
          border: 1px solid #2a2a2a;
          border-radius: 5px;
          margin: 0 5px;
          color: white;
          text-decoration: none;
          font-weight: bold;
          transition: background-color 0.3s ease;
        }

        .market-catalyst-ad:hover {
          background-color: #254eda; /* Slightly darker shade of blue for hover effect */
        }

        @media (max-width: 768px) {
          .ticker-tape-container {
            height: auto;
          }

          .ticker-item {
            padding: 8px 10px;
            font-size: 0.9em;
          }

          .market-catalyst-ad {
            padding: 8px 10px;
            font-size: 0.9em;
          }
        }

        .ticker-tape-content {
          display: flex;
          width: 200%;
        }
      `}</style>
    </div>
  );
};

export default CustomTickerTape;

