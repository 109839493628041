import React, { useState } from 'react';

function CancelSubscription() {
  const [message, setMessage] = useState('');

  const handleCancel = async () => {
    const response = await fetch('/api/cancel-subscription/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${localStorage.getItem('authToken')}`, // Adjust based on your auth system
      },
    });

    const data = await response.json();

    if (data.error) {
      setMessage(`Error: ${data.error}`);
    } else {
      setMessage('Your subscription has been canceled.');
    }
  };

  return (
    <div>
      <h2>Cancel Subscription</h2>
      <button onClick={handleCancel}>Cancel Subscription</button>
      {message && <p>{message}</p>}
    </div>
  );
}

export default CancelSubscription;
//
