import React from 'react';

const PaymentFailedPage = () => {
  return (
    <div className="error-container">
      <h1>Payment Failed</h1>
      <p>Your payment didn’t go through. Please try again or contact support for help.</p>
      <a href="/retry-payment" className="btn-primary">Retry Payment</a>
    </div>
  );
};

export default PaymentFailedPage;
//