import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import './RegistrationFormAPI.css';
import config from '../../config/config';

const RegistrationFormAPI = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [selectedPlan, setSelectedPlan] = useState(config.pricingPlans[0].id); // Default to first plan
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const onSubmit = async (data) => {
    setLoading(true);
    setErrorMessage(null);

    if (!stripe || !elements) {
      setErrorMessage('Stripe.js has not loaded yet. Please try again.');
      setLoading(false);
      return;
    }

    // Step 1: Register the user in the backend using username
    try {
      const userResponse = await axios.post(`${config.apiStagingUrl}/auth/users/`, {
        username: data.username,  // Use username for authentication
        email: data.email,        // Email is collected but not used for authentication
        password: data.password,
        firstName: data.firstName,
        lastName: data.lastName,
      });

      const userId = userResponse.data.id; // Get the user ID

      // Step 2: Create Stripe payment method
      const cardElement = elements.getElement(CardElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        console.error('Stripe error:', error);
        setErrorMessage(error.message);
        setLoading(false);
        return;
      }

      // Step 3: Create Stripe subscription and link it to the user in the backend
      const subscriptionResponse = await axios.post(`${config.apiStagingUrl}/subscribe/`, {
        paymentMethodId: paymentMethod.id,
        priceId: selectedPlan, // Stripe price ID from the selected plan
        userId: userId,        // User ID from the backend
      });

      if (subscriptionResponse.data.success) {
        const { stripeSubscriptionId } = subscriptionResponse.data;

        // Step 4: Update backend with the stripe_subscription_id
        await axios.put(`${config.apiStagingUrl}/auth/users/${userId}`, {
          stripe_subscription_id: stripeSubscriptionId,
        });

        console.log('Subscription and user update successful:', subscriptionResponse.data);
        // Handle success (e.g., navigate to a confirmation page)
      } else {
        setErrorMessage(subscriptionResponse.data.message || 'Subscription failed. Please try again.');
      }

    } catch (error) {
      if (error.response && error.response.data) {
        setErrorMessage(Object.values(error.response.data).join(' ') || 'An error occurred during registration. Please try again.');
      } else {
        setErrorMessage('An unexpected error occurred. Please try again later.');
      }
    }

    setLoading(false);
  };

  return (
    <div className="form-container">
      <h2 className="form-title">Sign Up</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="styled-form">
        <div className="form-row">
          {/* Username Field */}
          <div className="form-group">
            <label htmlFor="username" className="label">Username</label>
            <input id="username" {...register('username', { required: true })} type="text" className="input" />
            {errors.username && <span className="error-message">Username is required</span>}
          </div>
          
          {/* Email Field */}
          <div className="form-group">
            <label htmlFor="email" className="label">Email</label>
            <input id="email" {...register('email', { required: true })} type="email" className="input" />
            {errors.email && <span className="error-message">Email is required</span>}
          </div>
        </div>

        {/* Password Field */}
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="password" className="label">Password</label>
            <input id="password" {...register('password', { required: true })} type="password" className="input" />
            {errors.password && <span className="error-message">Password is required</span>}
          </div>
        </div>

        {/* First and Last Name Fields */}
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="firstName" className="label">First Name</label>
            <input id="firstName" {...register('firstName', { required: true })} type="text" className="input" />
            {errors.firstName && <span className="error-message">First Name is required</span>}
          </div>
          <div className="form-group">
            <label htmlFor="lastName" className="label">Last Name</label>
            <input id="lastName" {...register('lastName', { required: true })} type="text" className="input" />
            {errors.lastName && <span className="error-message">Last Name is required</span>}
          </div>
        </div>

        {/* Country Field */}
        <div className="form-group">
          <label htmlFor="country" className="label">Country</label>
          <select id="country" {...register('country', { required: true })} className="select">
            <option value="US">United States</option>
            <option value="CA">Canada</option>
            <option value="UK">United Kingdom</option>
            {/* Add more countries as needed */}
          </select>
          {errors.country && <span className="error-message">Country is required</span>}
        </div>

        {/* Plan Selection */}
        <h3 className="section-title">Select a Plan</h3>
        <div className="plan-options">
          {config.pricingPlans.map(plan => (
            <div key={plan.id} className="plan-option">
              <input
                type="radio"
                id={plan.id}
                value={plan.id}
                checked={selectedPlan === plan.id}
                onChange={() => setSelectedPlan(plan.id)}
              />
              <label htmlFor={plan.id} className="label">{plan.name} (${plan.price})</label>
            </div>
          ))}
        </div>

        {/* Promotion Code Field */}
        <div className="form-group">
          <label htmlFor="promoCode" className="label">Promotion Code</label>
          <input id="promoCode" {...register('promoCode')} type="text" className="input" placeholder="Enter code" />
        </div>
        
        {/* Payment Details */}
        <h3 className="section-title">Your Payment Details</h3>
        <div className="card-element-container">
          <CardElement options={{ style: { base: { fontSize: '16px' } } }} />
        </div>
        
        {errorMessage && <span className="error-message">{errorMessage}</span>}
        
        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? 'Processing...' : 'Create Account'}
        </button>
      </form>
    </div>
  );
};

export default RegistrationFormAPI;
