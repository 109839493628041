import React from 'react';
import config from '../config/config';

const { apiStagingUrl } = config;

const CompanyLogo = ({ ticker }) => {
    const baseUrl = `${apiStagingUrl}/staticfiles/company_logos/`;

    const handleImageError = (e) => {
        const img = e.target;
        const currentSrc = img.src;
        const lowerCaseSrc = `${baseUrl}${ticker.toLowerCase()}.png`;
        const upperCaseSrc = `${baseUrl}${ticker.toUpperCase()}.png`;

        if (currentSrc !== lowerCaseSrc) {
            img.src = lowerCaseSrc;
        } else if (currentSrc !== upperCaseSrc) {
            img.src = upperCaseSrc;
        } else {
            img.style.display = 'none';
        }
    };

    return (
        <img
            src={`${baseUrl}${ticker.toLowerCase()}.png`}
            alt={ticker}
            style={{ width: '100px', height: '100px' }}
            onError={handleImageError}
        />
    );
};

export default CompanyLogo;
