import React, { useState, useEffect } from 'react';

function SubscriptionStatus() {
  const [status, setStatus] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/api/subscription-status/', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${localStorage.getItem('authToken')}`, // Adjust based on your auth system
      },
    })
      .then((response) => response.json())
      .then((data) => setStatus(data.subscription_status))
      .catch((error) => setError('Failed to load subscription status'));
  }, []);

  return (
    <div>
      <h2>Your Subscription Status</h2>
      {error ? <div className="error">{error}</div> : <p>Status: {status}</p>}
    </div>
  );
}

export default SubscriptionStatus;
//