import React, { useEffect } from 'react';

const TickerTape = () => {
  useEffect(() => {
    // Check if the script is already added
    const existingScript = document.getElementById('ticker-tape-script');
    if (!existingScript) {
      const script = document.createElement('script');
      script.id = 'ticker-tape-script'; // Assign an ID to the script element
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
      script.async = true;
      script.innerHTML = JSON.stringify({
        "symbols": [
          { "proName": "FOREXCOM:SPXUSD", "title": "S&P 500" },
          { "proName": "FOREXCOM:NSXUSD", "title": "Nasdaq 100" },
          { "proName": "FOREXCOM:DJI", "title": "Dow Jones" },
          { "proName": "NASDAQ:TSLA", "title": "Tesla" },
          { "proName": "NASDAQ:AAPL", "title": "Apple" },
          { "proName": "NYSE:C", "title": "Citigroup" },
          { "proName": "NYSE:ICE", "title": "Intercontinental Exchange" },
          { "proName": "NYSE:SPGI", "title": "S&P Global" },
          { "proName": "NYSE:RY", "title": "Royal Bank of Canada" },
          { "proName": "NYSE:MS", "title": "Morgan Stanley" },
          { "proName": "NASDAQ:ALNY", "title": "Alnylam Pharmaceuticals" },
          { "proName": "NASDAQ:VRSK", "title": "Verisk Analytics" },
          { "proName": "NASDAQ:BIDU", "title": "Baidu" },
          { "proName": "NASDAQ:GOOGL", "title": "Alphabet (Google)" },
          { "proName": "NASDAQ:META", "title": "Meta Platforms" },
          { "proName": "NYSE:RDY", "title": "Dr. Reddy's Laboratories" },
          { "proName": "NASDAQ:HEPS", "title": "D-Market Electronic Services" },
          { "proName": "NYSE:TJX", "title": "TJX Companies" },
          { "proName": "NYSE:BAC", "title": "Bank of America" },
          { "proName": "NYSE:LLY", "title": "Eli Lilly" },
          { "proName": "NYSE:NVS", "title": "Novartis" },
          { "proName": "NASDAQ:ARGX", "title": "Argenx SE" },
          { "proName": "NASDAQ:UTHR", "title": "United Therapeutics" },
          { "proName": "NYSE:SMFG", "title": "Sumitomo Mitsui Financial Group" },
          { "proName": "NYSE:MUFG", "title": "Mitsubishi UFJ Financial Group" },
          { "proName": "NYSE:MCO", "title": "Moody's" },
          { "proName": "NASDAQ:MSFT", "title": "Microsoft" },
          { "proName": "NASDAQ:NVDA", "title": "Nvidia" },
          { "proName": "NYSE:TSM", "title": "Taiwan Semiconductor Manufacturing" },
          { "proName": "NASDAQ:ADBE", "title": "Adobe" },
          { "proName": "NASDAQ:AMZN", "title": "Amazon" },
          { "proName": "NYSE:RBLX", "title": "Roblox" },
          { "proName": "NASDAQ:ISRG", "title": "Intuitive Surgical" },
          { "proName": "NYSE:JPM", "title": "JPMorgan Chase" },
          { "proName": "NYSE:WMT", "title": "Walmart" },
          { "proName": "NASDAQ:COST", "title": "Costco" },
          { "proName": "NYSE:PEG", "title": "Public Service Enterprise Group" },
          { "proName": "NASDAQ:BKNG", "title": "Booking Holdings" },
          { "proName": "NYSE:JMIA", "title": "Jumia Technologies" },
          { "proName": "NYSE:LNG", "title": "Cheniere Energy" },
          { "proName": "NYSE:NEM", "title": "Newmont Corporation" },
          { "proName": "NYSE:AEM", "title": "Agnico Eagle Mines" },
          { "proName": "NYSE:KGC", "title": "Kinross Gold" },
          { "proName": "NYSE:RSG", "title": "Republic Services" },
          { "proName": "NYSE:WM", "title": "Waste Management" },
          { "proName": "NASDAQ:NFLX", "title": "Netflix" },
          { "proName": "NASDAQ:ABNB", "title": "Airbnb" },
          { "proName": "NYSE:CURV", "title": "Torrid Holdings" },
          { "proName": "NYSE:RELX", "title": "RELX PLC" },
          { "proName": "NYSE:WCN", "title": "Waste Connections" },
          { "proName": "NYSE:ECL", "title": "Ecolab" },
          { "proName": "NASDAQ:RGLD", "title": "Royal Gold" },
          { "proName": "NASDAQ:PAAS", "title": "Pan American Silver" },
          { "proName": "NYSE:KMI", "title": "Kinder Morgan" },
          { "proName": "NYSE:OKE", "title": "ONEOK" },
          { "proName": "NYSE:WMB", "title": "Williams Companies" },
          { "proName": "NYSE:ET", "title": "Energy Transfer" },
          { "proName": "NYSE:ORCL", "title": "Oracle" },
          { "proName": "NASDAQ:AMGN", "title": "Amgen" },
          { "proName": "NASDAQ:VRTX", "title": "Vertex Pharmaceuticals" },
          { "proName": "NASDAQ:CHTR", "title": "Charter Communications" },
          { "proName": "NASDAQ:BIIB", "title": "Biogen" },
          { "proName": "NASDAQ:CTAS", "title": "Cintas" },
          { "proName": "NYSE:WDS", "title": "Woodside Petroleum" },
          { "proName": "NYSE:FCX", "title": "Freeport-McMoRan" },
          { "proName": "NYSE:SAP", "title": "SAP SE" },
          { "proName": "NYSE:HON", "title": "Honeywell" },
          { "proName": "NYSE:HEI", "title": "Heico" },
          { "proName": "NYSE:MPLX", "title": "MPLX LP" },
          { "proName": "NYSE:SCCO", "title": "Southern Copper" },
          { "proName": "NYSE:BAH", "title": "Booz Allen Hamilton" },
          { "proName": "NYSE:GE", "title": "General Electric" },
          { "proName": "NYSE:SPOT", "title": "Spotify" },
          { "proName": "NYSE:PINS", "title": "Pinterest" },
          { "proName": "NYSE:CRM", "title": "Salesforce" },
          { "proName": "NYSE:CL", "title": "Colgate-Palmolive" },
          { "proName": "NYSE:COKE", "title": "Coca-Cola" },
          { "proName": "NYSE:BG", "title": "Bunge" },
          { "proName": "NYSE:BRFS", "title": "BRF S.A." },
          { "proName": "NYSE:GOLD", "title": "Barrick Gold" },
          { "proName": "NYSE:DUK", "title": "Duke Energy" },
          { "proName": "NYSE:FTS", "title": "Fortis Inc." },
          { "proName": "NYSE:DASH", "title": "DoorDash" },
          { "proName": "NYSE:GME", "title": "GameStop" },
          { "proName": "NASDAQ:PDD", "title": "Pinduoduo" },
          { "proName": "NASDAQ:SFM", "title": "Sprouts Farmers Market" },
          { "proName": "NYSE:CEG", "title": "Constellation Energy" },
          { "proName": "NYSE:ELPC", "title": "Enel Americas" },
          { "proName": "NYSE:EBR", "title": "Centrais Elétricas Brasileiras" },
          { "proName": "NYSE:LNT", "title": "Alliant Energy" },
          { "proName": "NYSE:IRM", "title": "Iron Mountain" },
          { "proName": "NYSE:DLR", "title": "Digital Realty Trust" },
          { "proName": "NYSE:ESS", "title": "Essex Property Trust" },
          { "proName": "NYSE:EGP", "title": "EastGroup Properties" },
          { "proName": "NYSE:NRG", "title": "NRG Energy" },
          { "proName": "NYSE:AVB", "title": "AvalonBay Communities" },
          { "proName": "NYSE:UDR", "title": "UDR Inc." },
          { "proName": "NYSE:SO", "title": "Southern Company" },
          { "proName": "NYSE:NEE", "title": "NextEra Energy" },
          { "proName": "NYSE:CHD", "title": "Church & Dwight" },
          { "proName": "NYSE:BHP", "title": "BHP Group" },
          { "proName": "NYSE:SHEL", "title": "Shell" },
          { "proName": "NASDAQ:EA", "title": "Electronic Arts" },
          { "proName": "NASDAQ:INTU", "title": "Intuit" },
          { "proName": "NYSE:NOW", "title": "ServiceNow" },
          { "proName": "NASDAQ:FRPT", "title": "Freshpet" },
          { "proName": "NASDAQ:DOC", "title": "Physicians Realty Trust" },
          { "proName": "NASDAQ:EQIX", "title": "Equinix" },
          { "proName": "NYSE:O", "title": "Realty Income" },
          { "proName": "NYSE:CPT", "title": "Camden Property Trust" },
          { "proName": "NYSE:BRBR", "title": "BellRing Brands" },
          { "proName": "NYSE:TTE", "title": "TotalEnergies" },
          { "proName": "NYSE:BP", "title": "BP" }
        ],
        "showSymbolLogo": true,
        "isTransparent": false,
        "displayMode": "adaptive",
        "colorTheme": "dark",
        "locale": "en"
      });
      document.getElementById('ticker-tape').appendChild(script);
    }

    // Cleanup function to remove the script if the component unmounts
    return () => {
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, []);

  return (
    <div className="tradingview-widget-container w-full h-12 mb-4" id="ticker-tape">
      <div className="tradingview-widget-container__widget h-full"></div>
    </div>
  );
};

export default TickerTape;

