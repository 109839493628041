import { motion } from 'framer-motion';

const FeaturesSection = () => {
  const features = [
    {
      title: 'Never Miss a Market-Moving Event',
      description: 'Stay informed with our premier Catalyst Calendar, covering earnings releases, economic events, FDA approvals, and more.',
      cta: 'Explore Calendars',
      link: '/stock-market-catalyst-calendars-home',
    },
    {
      title: 'Leverage Flagship and Sector Portfolios',
      description: 'Utilize our Market Catalysts flagship MC110 and unique sector portfolios to analyze current impacts and make informed trading decisions.',
      cta: 'Explore Portfolios',
      link: '/invest/portfolios',
    },
    {
      title: 'Anticipate Market Reactions',
      description: 'Gauge the potential impact of upcoming catalyst events with our Historical Impact Analysis and Expected Price Move forecasts, powered by advanced algorithms.',
      cta: 'Coming Soon',
      link: '#',  // Placeholder link for now
    },
  ];

  return (
    <section className="bg-white py-20">
      <div className="container mx-auto px-4">
        <motion.h2 
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-3xl md:text-4xl font-bold mb-12 text-center"
        >
          Key Features
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, delay: index * 0.2 }}
              className="bg-white shadow-lg rounded-lg p-6"
            >
              {/* Add your feature icon here */}
              <h3 className="text-xl font-bold mb-4">{feature.title}</h3>
              <p className="text-gray-600 mb-6">{feature.description}</p>
              {feature.link !== '#' ? (
                <a href={feature.link} className="text-blue-600 font-bold hover:underline">
                  {feature.cta}
                </a>
              ) : (
                <span className="text-gray-600 font-bold">{feature.cta}</span>
              )}
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;

