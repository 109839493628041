// src/analytics.js
import ReactGA from 'react-ga4';

const TRACKING_ID = 'G-X8X3DWK3KJ'; // Replace with your actual tracking ID
ReactGA.initialize(TRACKING_ID);

export const logPageView = () => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
};

export const logEvent = (category, action, label, value) => {
  ReactGA.event({
    category,
    action,
    label,
    value
  });
};

export const logException = (description = '', fatal = false) => {
  ReactGA.exception({ description, fatal });
};
