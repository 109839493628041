import React, { useEffect, useRef, memo } from 'react';

function StockScreenerLoser() {
  const container = useRef();

  useEffect(() => {
    const scriptAlreadyLoaded = Array.from(
      document.getElementsByTagName('script')
    ).some((script) => script.src.includes('embed-widget-screener.js'));
    
    if (!scriptAlreadyLoaded) {
      const script = document.createElement('script');
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-screener.js";
      script.async = true;
      script.innerHTML = JSON.stringify({
        "width": "100%",
        "height": 490,
        "defaultColumn": "overview",
        "defaultScreen": "top_losers",
        "market": "america",
        "showToolbar": true,
        "colorTheme": "dark",
        "locale": "en"
      });
      script.setAttribute('type', 'text/javascript');
      container.current.appendChild(script);
    }
  }, []);

  return (
    <div className="tradingview-widget-container w-full mt-8" style={{ height: '490px' }} ref={container}>
      <div className="tradingview-widget-container__widget w-full h-full"></div>
    </div>
  );
}

export default memo(StockScreenerLoser);
