// RegistrationAndSubscriptionForm.js

import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import axios from 'axios';
import './RegistrationAndSubscriptionForm.css'; // Import the CSS file
import { useNavigate, Link } from 'react-router-dom'; // Import useNavigate and Link
import config from '../../config/config'; // Import config for API URL
import { subscribeUser } from '@strapi-newsletter/react'; // Import the newsletter subscription function

const RegistrationAndSubscriptionForm = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [subscribe, setSubscribe] = useState(false); // Newsletter subscription state
  const [termsAccepted, setTermsAccepted] = useState(false); // Terms acceptance state
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [country, setCountry] = useState('US');
  const [promotionCode, setPromotionCode] = useState('');
  const [selectedPlanId, setSelectedPlanId] = useState(null); // For plan selection
  const [pricingPlans, setPricingPlans] = useState([]); // Pricing plans state
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  // Fetch pricing plans from API and filter for plan ID 5 and 6
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(`${config.apiStagingUrl}/api/get-plans/`);
        const filteredPlans = response.data.plans.filter(
          (plan) => plan.id === 5 || plan.id === 6
        ); // Only plans 5 and 6

        setPricingPlans(filteredPlans);

        // Pre-select the yearly plan (ID 6) as the most popular
        const yearlyPlan = filteredPlans.find((plan) => plan.id === 6);
        if (yearlyPlan) {
          setSelectedPlanId(yearlyPlan.id); // Set Yearly Plan as pre-selected
        }
        console.log('Pricing plans loaded:', filteredPlans);
      } catch (err) {
        console.error('Error fetching pricing plans:', err);
        setError('Failed to load pricing plans.');
      }
    };
    fetchPlans();
  }, []);

  // Fetch country options for dropdown
  const options = countryList().getData();

  const handleCountryChange = (selectedOption) => {
    setCountry(selectedOption.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    if (!stripe || !elements) {
      setError('Stripe.js has not loaded.');
      setLoading(false);
      return;
    }

    if (!termsAccepted) {
      setError('You must accept the Terms of Service and Privacy Policy.');
      setLoading(false);
      return;
    }

    try {
      // Step 1: Register the user using the correct API endpoint
      console.log('Attempting to register user:', {
        username,
        email,
        firstName,
        lastName,
        country,
      });
      const registerResponse = await axios.post(
        `${config.apiStagingUrl}/auth/users/`,
        {
          username,
          email,
          password,
          first_name: firstName,
          last_name: lastName,
          country,
        }
      );

      console.log('User registered successfully.', registerResponse.data);

      // Step 1.5: Subscribe to newsletter if checked
      if (subscribe) {
        try {
          await subscribeUser(
            email,
            'https://victorious-bird-78d1c6c5c3.strapiapp.com'
          );
          console.log('User subscribed to newsletter.');
        } catch (error) {
          console.error('Error subscribing to newsletter:', error);
          // Optionally, set an error message or proceed without failing the registration
        }
      }

      // Step 2: Log in the user to get the auth token
      console.log('Logging in the user...');
      const loginResponse = await axios.post(
        `${config.apiStagingUrl}/auth/token/login/`,
        {
          username,
          password,
        }
      );

      console.log('Login response data:', loginResponse.data);

      const token = loginResponse.data.auth_token || loginResponse.data.token;

      if (!token) {
        throw new Error('Authentication token not received.');
      }

      console.log('User logged in successfully, token received:', token);

      // Step 3: Create the payment method
      const cardElement = elements.getElement(CardElement);
      console.log('Creating payment method...');
      const { error: paymentMethodError, paymentMethod } =
        await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });

      if (paymentMethodError) {
        setError(paymentMethodError.message);
        console.error('Payment method error:', paymentMethodError.message);
        setLoading(false);
        return;
      }

      console.log('Payment method created successfully:', paymentMethod.id);

      // Step 4: Look up the stripe_price_id based on the selected internal plan ID
      const selectedPlan = pricingPlans.find(
        (plan) => plan.id === parseInt(selectedPlanId)
      );
      const stripePriceId = selectedPlan ? selectedPlan.stripe_price_id : null;

      if (!stripePriceId) {
        setError('Selected plan does not have an associated Stripe price ID.');
        setLoading(false);
        return;
      }

      // Step 5: Create the subscription using the correct API endpoint
      console.log('Creating subscription...');
      console.log('Headers:', {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      });
      console.log('Payload:', {
        payment_method_id: paymentMethod.id,
        price_id: stripePriceId,
        promotion_code: promotionCode,
      });
      const subscriptionResponse = await axios.post(
        `${config.apiStagingUrl}/api/create-subscription/`,
        {
          payment_method_id: paymentMethod.id,
          price_id: stripePriceId, // Use Stripe price ID for the selected plan
          promotion_code: promotionCode,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Subscription response data:', subscriptionResponse.data);

      const { subscription, client_secret } = subscriptionResponse.data;
      console.log('Subscription created:', subscription.id);

      if (
        subscription.status === 'active' ||
        subscription.status === 'trialing'
      ) {
        console.log(
          'Subscription is active or in trial. Redirecting to confirmation...'
        );
        navigate('/confirmation');
      } else if (subscription.status === 'incomplete') {
        console.log('Subscription incomplete, handling 3D Secure...');
        const { error: confirmError } = await stripe.confirmCardPayment(
          client_secret
        );
        if (confirmError) {
          setError(`Payment failed: ${confirmError.message}`);
          console.error('Payment confirmation error:', confirmError.message);
          setLoading(false);
          return;
        } else {
          console.log('Payment succeeded. Redirecting to confirmation...');
          navigate('/confirmation');
        }
      } else {
        console.error('Subscription status is unknown:', subscription.status);
        setError('Subscription status is unknown.');
        setLoading(false);
      }
    } catch (err) {
      console.error(
        'Error during registration or subscription creation:',
        err
      );
      if (err.response) {
        setError(
          err.response.data.error ||
            err.response.data.non_field_errors?.[0] ||
            err.response.data.username?.[0] ||
            err.response.data.email?.[0] ||
            err.response.data.password?.[0] ||
            err.response.data.detail ||
            'An error occurred during registration.'
        );
      } else if (err.request) {
        setError('No response from server. Please try again later.');
      } else {
        setError('An unexpected error occurred.');
      }
      setLoading(false);
    }
  };

  return (
    <div className="form-container">
      <h2 className="form-title">Register and Subscribe</h2>
      {error && <div className="error-message">{error}</div>}
      <form onSubmit={handleSubmit} className="styled-form">
        {/* User registration fields */}
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="username" className="label">
              Username
            </label>
            <input
              id="username"
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              className="input"
            />
          </div>

          <div className="form-group">
            <label htmlFor="email" className="label">
              Email
            </label>
            <input
              id="email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="input"
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="password" className="label">
              Password
            </label>
            <input
              id="password"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="input"
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="firstName" className="label">
              First Name
            </label>
            <input
              id="firstName"
              type="text"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              className="input"
            />
          </div>

          <div className="form-group">
            <label htmlFor="lastName" className="label">
              Last Name
            </label>
            <input
              id="lastName"
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              className="input"
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="country" className="label">
            Country
          </label>
          <Select
            options={options}
            value={options.find((option) => option.value === country)}
            onChange={handleCountryChange}
            className="input"
          />
        </div>

        <div className="form-group">
          <label htmlFor="promotionCode" className="label">
            Promotion Code
          </label>
          <input
            id="promotionCode"
            type="text"
            placeholder="Enter promotion code"
            value={promotionCode}
            onChange={(e) => setPromotionCode(e.target.value)}
            className="input"
          />
        </div>

        {/* Pricing Plan Selection */}
        <h3 className="section-title">Choose Your Plan</h3>
        <div className="form-group">
          <select
            value={selectedPlanId}
            onChange={(e) => setSelectedPlanId(e.target.value)}
            required
            className="input"
          >
            <option value="">Select Plan</option>
            {pricingPlans.map((plan) => (
              <option key={plan.id} value={plan.id}>
                {plan.name} - ${parseFloat(plan.price).toFixed(2)}
                {plan.id === 6 && ' ★ Most Popular'}
              </option>
            ))}
          </select>
        </div>

        {/* Product Description Section */}
        <div className="product-description">
          <h4>What's included in your subscription:</h4>
          <ul>
            <li>Market Catalyst Calendar</li>
            <li>Stock Portfolios</li>
            <li>Earnings Calendars</li>
            <li>FDA Calendar</li>
            {/* Add more items as needed */}
          </ul>
          <p className="trial-note">Includes a 7-day free trial</p>
        </div>

        {/* Payment Details */}
        <h3 className="section-title">Your Payment Details</h3>
        <div className="card-element-container">
          <CardElement options={{ style: { base: { fontSize: '16px' } } }} />
        </div>

        {/* Newsletter Subscription */}
        <div className="form-group">
          <label className="checkbox-label">
            <input
              type="checkbox"
              checked={subscribe}
              onChange={(e) => setSubscribe(e.target.checked)}
              className="checkbox-input"
            />
            Subscribe to the newsletter
          </label>
        </div>

        {/* Terms of Service and Privacy Policy */}
        <div className="form-group terms-acceptance">
          <label className="checkbox-label">
            <input
              type="checkbox"
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
              className="checkbox-input"
            />
            I agree to the{' '}
            <Link to="/terms-of-use" target="_blank" rel="noopener noreferrer">
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link
              to="/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Link>
            .
          </label>
        </div>

        <button
          type="submit"
          className="submit-button"
          disabled={!stripe || loading}
        >
          {loading ? 'Processing...' : 'Register and Subscribe'}
        </button>

        {/* Optional: Add a note about existing users */}
        <div className="form-footer">
          Already a user?{' '}
          <Link to="/login" className="footer-link">
            Log in here
          </Link>
          .
        </div>
      </form>
    </div>
  );
};

export default RegistrationAndSubscriptionForm;
