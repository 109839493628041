import { motion } from 'framer-motion';

const CTASection = () => {
  return (
    <motion.section 
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.8 }}
      className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-20"
    >
      <div className="container mx-auto px-4 text-center">
        <motion.h2 
          initial={{ opacity: 0, y: -50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="text-3xl md:text-4xl font-bold mb-6"
        >
          Empower Your Trading with Market Catalysts
        </motion.h2>
        <motion.p 
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="text-xl mb-8"
        >
          Sign up now for access to our game-changing tools and insights.
        </motion.p>
        <motion.div 
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          <a href="/register" className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-50">
            Get Started
          </a>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default CTASection;