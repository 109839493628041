// StripePayment.js
import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation } from 'react-router-dom';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || 'pk_test_51OWoegK7imM49pkJaWb7HQwErr8WKynzV07lgkHUQkvWabdIUdnVJwflATc5QlTJJxZdEgx6irxlomQCZVlNAswE00Sc47syj5'
);

const StripePayment = () => {
  const location = useLocation();
  const cartItems = location.state?.cartItems || []; // Provide a default empty array if location.state is null

  console.log('Cart items:', cartItems);

  return (
    <div className="container mx-auto">
      <Elements stripe={stripePromise}>
        <CheckoutForm cartItems={cartItems} />
      </Elements>
    </div>
  );
};

export default StripePayment;