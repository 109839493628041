import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import config from '../../config/config'; // Adjusted to match your folder structure
import RegistrationFormAPI from './RegistrationFormAPI'; // Import your existing form component

// Initialize Stripe with the public key from the config file
const stripePromise = loadStripe(config.stripePublishableKey);

const RegistrationFormAPIWrapper = () => {
  return (
    <Elements stripe={stripePromise}>
      <RegistrationFormAPI />
    </Elements>
  );
};

export default RegistrationFormAPIWrapper;
