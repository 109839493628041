import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const ThankYouPage = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.6 } },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Thank You - Market Catalysts",
    "url": "https://www.marketcatalysts.com/thank-you",
    "description": "Thank you for subscribing to Market Catalysts. Your subscription has been successfully processed.",
    "publisher": {
      "@type": "Organization",
      "name": "Market Catalysts",
      "logo": {
        "@type": "ImageObject",
        "url": "https://www.marketcatalysts.com/mc.png"
      }
    },
    "inLanguage": "en-US",
    "datePublished": "2024-08-01",
    "dateModified": "2024-08-01"
  };
  
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Thank You - Market Catalysts</title>
        <meta name="description" content="Thank you for subscribing to Market Catalysts. Your subscription has been successfully processed." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/mc.png" />
        <link rel="canonical" href="https://www.marketcatalysts.com/thank-you" />
        <meta name="keywords" content="subscription, market catalysts, thank you" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.marketcatalysts.com/thank-you" />
        <meta property="og:title" content="Thank You - Market Catalysts" />
        <meta property="og:description" content="Thank you for subscribing to Market Catalysts. Your subscription has been successfully processed." />
        <meta property="og:image" content="https://www.marketcatalysts.com/thank-you-og.jpg" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.marketcatalysts.com/thank-you" />
        <meta property="twitter:title" content="Thank You - Market Catalysts" />
        <meta property="twitter:description" content="Thank you for subscribing to Market Catalysts. Your subscription has been successfully processed." />
        <meta property="twitter:image" content="https://www.marketcatalysts.com/thank-you-twitter.jpg" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <motion.div
        className="min-h-screen flex items-center justify-center bg-gray-100"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <div className="bg-white p-8 rounded shadow-md text-center max-w-md mx-auto">
          <motion.h1
            className="text-4xl font-bold mb-4"
            variants={itemVariants}
          >
            Thank You for Subscribing!
          </motion.h1>
          <motion.p
            className="text-xl mb-8"
            variants={itemVariants}
          >
            Your subscription has been successfully processed. You now have access to all the premium features of Market Catalysts.
          </motion.p>
          <motion.div
            className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4"
            variants={itemVariants}
          >
            <Link
              to="/login"
              className="bg-blue-500 text-white px-6 py-3 rounded font-semibold hover:bg-blue-600 transition duration-200"
            >
              Login to Your Account
            </Link>
            <Link
              to="/"
              className="bg-gray-200 text-gray-700 px-6 py-3 rounded font-semibold hover:bg-gray-300 transition duration-200"
            >
              Back to Home
            </Link>
          </motion.div>
        </div>
      </motion.div>
    </>
  );
};

export default ThankYouPage;
