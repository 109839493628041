import React, { useEffect, useRef, memo } from 'react';

function TradingViewStockScreenerWidget() {
  const container = useRef();

  useEffect(() => {
    // Avoid duplicates
    const scriptAlreadyLoaded = Array.from(
      document.getElementsByTagName('script')
    ).some((script) => script.src.includes('embed-widget-screener.js'));
    
    if (!scriptAlreadyLoaded) {
      const script = document.createElement('script');
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-screener.js";
      script.async = true;
      // Properly escape the JSON string
      script.innerHTML = JSON.stringify({
        "width": "100%",
        "height": 490, // Set a fixed height or "100%" if you want it to be full height
        "defaultColumn": "overview",
        "defaultScreen": "most_capitalized",
        "market": "america",
        "showToolbar": true,
        "colorTheme": "dark",
        "locale": "en"
      });
      // This line helps to avoid any potential issues with CSPs (Content Security Policies)
      script.setAttribute('type', 'text/javascript');
      container.current.appendChild(script);
    }
  }, []);

  return (
    // Apply Tailwind CSS classes for styling
    <div className="tradingview-widget-container w-full" style={{ height: '490px' }} ref={container}>
      <div className="tradingview-widget-container__widget w-full h-full"></div>
      {/* The copyright text can be left as is or adjusted if needed */}
    </div>
  );
}

export default memo(TradingViewStockScreenerWidget);
