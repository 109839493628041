import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import config from '../../config/config';

const ResetPassword = () => {
  const { apiStagingUrl } = config;

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const { uid, token } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsError(false);
    setFeedbackMessage('');

    if (newPassword !== confirmPassword) {
      setIsError(true);
      setFeedbackMessage('Passwords do not match!');
      return;
    }

    try {
      const response = await axios.post(`${apiStagingUrl}/auth/users/reset_password_confirm/`, {
        uid: encodeURIComponent(uid),
        token: encodeURIComponent(token),
        new_password: newPassword,
        re_new_password: confirmPassword,
      });

      if (response.status === 204 || response.status === 200) {
        setFeedbackMessage('Password has been successfully reset. Redirecting to login...');
        setTimeout(() => navigate('/login'), 3000);
      }
    } catch (error) {
      setIsError(true);
      if (error.response) {
        setFeedbackMessage(error.response.data?.detail || 'Failed to reset password. Please ensure the link is correct or contact support.');
      } else {
        setFeedbackMessage('Network error or server is down. Please try again later.');
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="w-full max-w-md">
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="newPassword">
              New Password
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="newPassword"
              type="password"
              placeholder="New password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmPassword">
              Confirm Password
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="confirmPassword"
              type="password"
              placeholder="Confirm new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Change Password
            </button>
          </div>
          {feedbackMessage && (
            <p className={`text-center mt-4 ${isError ? 'text-red-500' : 'text-green-500'}`}>
              {feedbackMessage}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;



