import { FaTwitter, FaYoutube, FaLinkedin, FaDiscord, FaTiktok, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { subscribeUser } from "@strapi-newsletter/react";

const Footer = () => {
  const [email, setEmail] = useState('');
  const [subscribeMessage, setSubscribeMessage] = useState('');

  const handleSubscribe = async (e) => {
    e.preventDefault();

    try {
      await subscribeUser(email, "https://victorious-bird-78d1c6c5c3.strapiapp.com");
      setSubscribeMessage('Successfully subscribed to the newsletter!');
      setEmail('');
    } catch (error) {
      console.error('Error subscribing to newsletter:', error);
      setSubscribeMessage('Error subscribing to the newsletter. Please try again.');
    }
  };

  return (
    <footer className="bg-gray-900 text-white py-8">
      <div className="container mx-auto px-4">
        {/* Newsletter, links, and social media sections */}
        <div className="text-center mb-8">
          <h3 className="text-xl font-bold mb-4">Market Catalysts Newsletter</h3>
          <form onSubmit={handleSubscribe} className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4">
            <input
              type="email"
              className="w-full px-4 py-2 mb-2 md:mb-0 md:mr-2 text-gray-900 bg-white border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none">
              Subscribe
            </button>
          </form>
          {subscribeMessage && <p className="text-gray-400 mt-2">{subscribeMessage}</p>}
        </div>

        {/* Company, Products, and Follow Us Sections */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-x-6">
          <div>
            <h3 className="text-lg font-bold mb-4">Company</h3>
            <ul className="text-gray-400 space-y-2">
              <li><Link to="/about-us">About Us</Link></li>
              <li><Link to="/team">Team</Link></li>
              <li><Link to="/contact-us">Contact Us</Link></li>
              <li><Link to="/terms-of-use">Terms of Use</Link></li>
              <li><Link to="/privacy-policy">Privacy Policy</Link></li>
              <li><Link to="/data-disclaimer">Data Disclaimer</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-bold mb-4">Products</h3>
            <ul className="text-gray-400 space-y-2">
              <li><Link to="/stock-market-catalyst-calendars-home">Catalysts Calendars</Link></li>
              <li><Link to="/invest/portfolios">Portfolios</Link></li>
              <li><Link to="/weekly-watch-list">Weekly Watch List</Link></li>
              <li><Link to="/company/AAPL">Companies</Link></li>
              <li><Link to="/trading">Screener</Link></li>
              <li><Link to="/stock-heatmap">Stock Heatmap</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-bold mb-4">Follow Us</h3>
            <div className="flex justify-center md:justify-start space-x-4">
              <a href="https://x.com/MarketCatalyts" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                <FaTwitter size={24} />
              </a>
              <a href="https://www.youtube.com/@MarketCatalysts11" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                <FaYoutube size={24} />
              </a>
              <a href="https://www.linkedin.com/company/marketcatalysts" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                <FaLinkedin size={24} />
              </a>
              <a href="https://discord.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                <FaDiscord size={24} />
              </a>
              <a href="https://tiktok.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                <FaTiktok size={24} />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                <FaInstagram size={24} />
              </a>
            </div>
          </div>
        </div>

        <hr className="border-gray-700 my-8" />

        {/* SEO-driven long description with market events */}
        <div className="text-center text-gray-500 text-sm mt-4">
          <p>
            Never miss the next big stock market catalyst. Market Catalysts keeps you informed about major market-moving events, including:
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-x-6 mt-4">
            <ul className="text-left space-y-2">
              <li><strong>Federal Reserve Rate Cuts</strong> - $SPX, $DJI</li>
              <li><strong>Jackson Hole Symposium</strong> - $TLT, $UUP</li>
              <li><strong>U.S. Inflation Reports (CPI/PPI)</strong> - $SPY, $QQQ</li>
              <li><strong>Black Friday & Holiday Sales</strong> - $AMZN, $WMT</li>
              <li><strong>Apple Iphone & Product Launches</strong> - $AAPL</li>
              <li><strong>Nonfarm Payrolls</strong> - $DXY, $TLT</li>
            </ul>
            <ul className="text-left space-y-2">
              <li><strong>Tesla Vehicle Unveilings</strong> - $TSLA</li>
              <li><strong>Nvidia GTC Conference</strong> - $NVDA</li>
              <li><strong>Bitcoin Halving</strong> - $BTC-USD, $GBTC</li>
              <li><strong>Santa Claus Rally</strong> - $SPY, $QQQ</li>
              <li><strong>Amazon Prime Day</strong> - $AMZN, $XLY</li>
              <li><strong>Super Bowl</strong> - $PEP, $CMCSA</li>
            </ul>
            <ul className="text-left space-y-2">
              <li><strong>Earnings Season</strong> - $MSFT, $META</li>
              <li><strong>OPEC Meetings</strong> - $CL=F, $XLE, $CVX</li>
              <li><strong>FDA Drug Approvals</strong> - $PFE, $MRNA, $JNJ</li>
              <li><strong>IPO Debuts</strong> - $RIVN, $COIN</li>
              <li><strong>Federal Budget & Debt Ceiling Discussions</strong> - $TLT, $SPY</li>
              <li><strong>Advancements in AI</strong> - $NVDA, $AMD, ChatGPT</li>
            </ul>
          </div>
        </div>

        <hr className="border-gray-700 my-8" />

        {/* Expanded Disclaimer and Copyright */}
        <div className="text-center text-gray-500 text-sm">
          <p>
            Disclaimer: Investing involves risk, including the potential loss of principal. Past performance does not guarantee future results, and trading on market catalysts carries additional risks due to the unpredictability of events. It's essential to conduct thorough research or consult a qualified financial advisor before making any investment decisions.
          </p>
          <p className="mt-4">
            &copy; 2024 Market Catalysts, LLC. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
