import React from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import FeaturesSection from '../components/HomePage/FeaturesSection';
import TestimonialsSection from '../components/HomePage/TestimonialsSection';
import CTASection from '../components/HomePage/CTASection';

function HomePage() {
  const pageTitle = "Market Catalysts - Turning Market Events into Opportunities";

  const structuredData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebSite",
        "@id": "https://www.marketcatalysts.com/#website",
        "url": "https://www.marketcatalysts.com/",
        "name": pageTitle,
        "description": "Your premier trading companion for identifying market catalysts, analyzing stock trends, and developing profitable trading strategies. Your edge in a dynamic trading world.",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://www.marketcatalysts.com/search?q={search_term_string}",
          "query-input": "required name=search_term_string"
        },
        "inLanguage": "en-US",
        "keywords": "market catalysts, trading strategies, stock analysis, financial news, investment research, technical analysis, market insights, market events"
      },
      {
        "@type": "Organization",
        "@id": "https://www.marketcatalysts.com/#organization",
        "name": "Market Catalysts",
        "url": "https://www.marketcatalysts.com/",
        "logo": {
          "@type": "ImageObject",
          "url": "https://www.marketcatalysts.com/mc172x192.png",
          "width": 172,
          "height": 192
        },
        "sameAs": [
          "https://twitter.com/marketcatalysts",
          "https://www.linkedin.com/company/marketcatalysts"
        ],
        "contactPoint": {
          "@type": "ContactPoint",
          "email": "contact@marketcatalysts.com",
          "contactType": "customer service"
        }
      },
      {
        "@type": "WebPage",
        "@id": "https://www.marketcatalysts.com/#webpage",
        "url": "https://www.marketcatalysts.com/",
        "name": pageTitle,
        "isPartOf": { "@id": "https://www.marketcatalysts.com/#website" },
        "about": { "@id": "https://www.marketcatalysts.com/#organization" },
        "description": "Gain your trading edge with Market Catalysts. We turn market events into opportunities, providing you with crucial insights in a dynamic trading world.",
        "inLanguage": "en-US",
        "mainEntity": [
          {
            "@type": "Service",
            "name": "Market Event Analysis",
            "description": "Identify and analyze key market events that drive stock prices and market trends."
          },
          {
            "@type": "Service",
            "name": "Trading Strategy Development",
            "description": "Develop and refine trading strategies based on market event analysis and historical data."
          },
          {
            "@type": "Service",
            "name": "Stock Trend Analysis",
            "description": "Analyze stock trends using advanced technical and fundamental analysis tools."
          },
          {
            "@type": "Service",
            "name": "Market Sentiment Tracking",
            "description": "Track and analyze market sentiment to predict potential market movements."
          }
        ]
      }
    ]
  };

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{pageTitle}</title>
        <meta name="description" content="Gain your trading edge with Market Catalysts. We turn market events into opportunities, providing you with crucial insights in a dynamic trading world." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="%PUBLIC_URL%/favicon-market-catalysts.ico" />
        <link rel="apple-touch-icon" href="%PUBLIC_URL%/mc.png" />
        <link rel="canonical" href="https://www.marketcatalysts.com/" />
        <meta name="keywords" content="market catalysts, trading strategy, stock analysis, financial news, technical analysis, market insights, market events, trading opportunities" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.marketcatalysts.com/" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content="Gain your trading edge with Market Catalysts. We turn market events into opportunities, providing you with crucial insights in a dynamic trading world." />
        <meta property="og:image" content="https://www.marketcatalysts.com/1200600.png" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.marketcatalysts.com/" />
        <meta property="twitter:title" content={pageTitle} />
        <meta property="twitter:description" content="Gain your trading edge with Market Catalysts. We turn market events into opportunities, providing you with crucial insights in a dynamic trading world." />
        <meta property="twitter:image" content="https://www.marketcatalysts.com/1200675.png" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <main>
        <motion.section 
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-20 relative overflow-hidden"
        >
          <div className="container mx-auto px-4">
            <div className="text-center relative z-10">
              <motion.h1 
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                className="text-5xl md:text-6xl lg:text-7xl font-bold mb-4"
              >
                Market Catalysts
              </motion.h1>
              <motion.h2
                initial={{ opacity: 0, y: -30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
                className="text-3xl md:text-4xl lg:text-5xl font-semibold mb-6"
              >
                Turning Market Events into Opportunities
              </motion.h2>
              <motion.p 
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.6 }}
                className="text-xl md:text-2xl mb-4"
              >
                Your edge in a dynamic trading world
              </motion.p>
              <motion.p 
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.8 }}
                className="text-lg md:text-xl mb-8 max-w-3xl mx-auto"
              >
                Stay ahead of the market with our comprehensive Catalyst Calendars, Historical Impact Analysis, and Expected Price Move Forecasts.
              </motion.p>
              <motion.div 
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 1 }}
                className="flex justify-center space-x-4"
              >
                <a href="/register" className="bg-blue-600 text-white font-bold py-3 px-8 rounded-full hover:bg-blue-700 transition duration-300">
                  Unlock Catalysts Insights
                </a>
              </motion.div>
            </div>
            <div className="absolute inset-0 z-0 opacity-30">
              <motion.div 
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 1, delay: 0.5 }}
                className="absolute top-10 left-10 w-24 h-24 bg-blue-300 rounded-full filter blur-xl"
              />
              <motion.div 
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 1, delay: 0.7 }}
                className="absolute bottom-10 right-10 w-32 h-32 bg-purple-400 rounded-full filter blur-xl"
              />
              <motion.div 
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 1, delay: 0.9 }}
                className="absolute bottom-20 left-1/2 w-28 h-28 bg-pink-400 rounded-full filter blur-xl transform -translate-x-1/2"
              />
            </div>
          </div>
        </motion.section>

        <FeaturesSection />
        <TestimonialsSection />
        <CTASection />
      </main>
    </div>
  );
}

export default HomePage;
