import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import TickerTape from '../components/TradingViewWidgets/TickerTape';
import AdvancedChart from '../components/TradingViewWidgets/AdvancedChart';
import TradingViewHeatMapWidget from '../components/TradingViewWidgets/HeatMap';
import StockScreenerMC from '../components/TradingViewWidgets/StockScreenerMC';
import StockScreenerGainer from '../components/TradingViewWidgets/StockScreenerGainer';
import StockScreenerLoser from '../components/TradingViewWidgets/StockScreenerLoser';

function TradingPage() {
  const [showAdvancedChart, setShowAdvancedChart] = useState(false);
  const [showHeatMapWidget, setShowHeatMapWidget] = useState(false);
  const [showStockScreenerMC, setShowStockScreenerMC] = useState(false);
  const [showStockScreenerGainer, setShowStockScreenerGainer] = useState(false);
  const [showStockScreenerLoser, setShowStockScreenerLoser] = useState(false);

  useEffect(() => {
    setShowAdvancedChart(true);
  }, []);

  useEffect(() => {
    if (showAdvancedChart) {
      const timer = setTimeout(() => setShowHeatMapWidget(true), 500);
      return () => clearTimeout(timer);
    }
  }, [showAdvancedChart]);

  useEffect(() => {
    if (showHeatMapWidget) {
      const timer = setTimeout(() => setShowStockScreenerMC(true), 500);
      return () => clearTimeout(timer);
    }
  }, [showHeatMapWidget]);

  useEffect(() => {
    if (showStockScreenerMC) {
      const timer = setTimeout(() => setShowStockScreenerGainer(true), 500);
      return () => clearTimeout(timer);
    }
  }, [showStockScreenerMC]);

  useEffect(() => {
    if (showStockScreenerGainer) {
      const timer = setTimeout(() => setShowStockScreenerLoser(true), 500);
      return () => clearTimeout(timer);
    }
  }, [showStockScreenerGainer]);

  const structuredData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        "@id": "https://www.marketcatalysts.com/trading#webpage",
        "url": "https://www.marketcatalysts.com/trading",
        "name": "Trading View - Market Catalysts",
        "description": "View advanced charts, heatmaps, and stock screeners.",
        "isPartOf": {"@id": "https://www.marketcatalysts.com/#website"},
        "inLanguage": "en-US"
      },
      {
        "@type": "WebSite",
        "@id": "https://www.marketcatalysts.com/#website",
        "url": "https://www.marketcatalysts.com/",
        "name": "Market Catalysts",
        "description": "Your Trading Companion for market catalysts and trading strategies",
        "publisher": {"@id": "https://www.marketcatalysts.com/#organization"},
        "inLanguage": "en-US"
      },
      {
        "@type": "Organization",
        "@id": "https://www.marketcatalysts.com/#organization",
        "name": "Market Catalysts",
        "url": "https://www.marketcatalysts.com/",
        "logo": {
          "@type": "ImageObject",
          "url": "https://www.marketcatalysts.com/mc%20172x192.png",
          "width": 172,
          "height": 192
        }
      }
    ]
  };

  return (
    <div className="flex flex-col items-center justify-start min-h-screen bg-gray-900 text-white">
      <Helmet>
        <title>Trading Page - Market Catalysts</title>
        <meta name="description" content="View advanced charts, heatmaps, and stock screeners." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
        <link rel="apple-touch-icon" href="%PUBLIC_URL%/mc.png" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.marketcatalysts.com/trading" />
        <meta property="og:title" content="Trading Page - Market Catalysts" />
        <meta property="og:description" content="View advanced charts, heatmaps, and stock screeners." />
        <meta property="og:image" content="%PUBLIC_URL%/mc.png" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.marketcatalysts.com/trading" />
        <meta property="twitter:title" content="Trading Page - Market Catalysts" />
        <meta property="twitter:description" content="View advanced charts, heatmaps, and stock screeners." />
        <meta property="twitter:image" content="%PUBLIC_URL%/mc.png" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="bg-gradient-to-r from-blue-500 to-purple-600 w-full py-10">
        <div className="text-center">
          <motion.h1 
            initial={{ opacity: 0, y: -30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-4xl md:text-5xl font-bold"
          >
            Stock Screener
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="text-xl mt-4"
          >
            View advanced charts, heatmaps, and stock screeners.
          </motion.p>
        </div>
      </div>
      <TickerTape />
      <div className="flex flex-col w-full items-center space-y-4 px-2 md:px-4 lg:px-8">
        {showAdvancedChart && (
          <div className="w-full max-w-full px-4 lg:px-0">
            <AdvancedChart />
          </div>
        )}
        {showHeatMapWidget && (
          <div className="w-full max-w-full px-4 lg:px-0">
            <TradingViewHeatMapWidget />
          </div>
        )}
        {showStockScreenerMC && (
          <div className="w-full max-w-full px-4 lg:px-0">
            <StockScreenerMC />
          </div>
        )}
        {showStockScreenerGainer && (
          <div className="w-full max-w-full px-4 lg:px-0">
            <StockScreenerGainer />
          </div>
        )}
        {showStockScreenerLoser && (
          <div className="w-full max-w-full px-4 lg:px-0">
            <StockScreenerLoser />
          </div>
        )}
      </div>
    </div>
  );
}

export default TradingPage;
