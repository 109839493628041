import React, { useEffect, useRef, memo } from 'react';

function TradingViewWidget() {
  const container = useRef();

  useEffect(() => {
    const scriptAlreadyLoaded = Array.from(
      document.getElementsByTagName('script')
    ).some((script) => script.src.includes('embed-widget-advanced-chart.js'));

    if (!scriptAlreadyLoaded) {
      const script = document.createElement('script');
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
      script.async = true;
      script.innerHTML = JSON.stringify({
        "width": "100%",
        "height": "610",
        "symbol": "CME_MINI:ES1!",
        "timezone": "America/New_York",
        "theme": "dark",
        "style": "1",
        "locale": "en",
        "enable_publishing": false,
        "backgroundColor": "rgba(0, 0, 0, 1)",
        "gridColor": "rgba(0, 0, 255, 0.06)",
        "range": "12M",
        "allow_symbol_change": true,
        "watchlist": [
          "CME_MINI:ES1!",
          "CME_MINI:NQ1!",
          "CBOT_MINI:YM1!"
        ],
        "details": true,
        "hotlist": true,
        "calendar": false,
        "studies": [
          "STD;Bollinger_Bands",
          "STD;WMA",
          "STD;Stochastic"
        ],
        "support_host": "https://www.tradingview.com"
      });
      container.current.appendChild(script);
    }
  }, []);

  return (
    // Add mx-auto to center the widget and px-4 for padding on the left and right
    <div className="tradingview-widget-container w-full h-screen mx-auto px-4" ref={container}>
      <div className="tradingview-widget-container__widget w-full h-full"></div>
      <div className="tradingview-widget-copyright text-right">
        <a href="https://www.tradingview.com" rel="noopener noreferrer" target="_blank" className="text-blue-600 hover:text-blue-800">
          Track all markets on TradingView
        </a>
      </div>
    </div>
  );
}

export default memo(TradingViewWidget);
