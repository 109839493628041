// UserProfile.js

import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { AuthContext } from '../../context/AuthContext';
import config from '../../config/config';
import { FaUser, FaEnvelope, FaCreditCard, FaSignOutAlt } from 'react-icons/fa';
import styled from 'styled-components';
import ReactModal from 'react-modal';

const ProfileContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  min-height: 100vh;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const ProfileCard = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
`;

const ProfileHeader = styled.h2`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;

const ProfileInfo = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;

const Button = styled.button`
  background-color: #007bff; /* Bootstrap primary color */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  margin-top: 20px;

  &:hover {
    background-color: #0056b3;
  }
`;

const CancelButton = styled.button`
  background-color: #dc3545; /* Bootstrap danger color */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  margin-right: 10px;

  &:hover {
    background-color: #c82333;
  }
`;

const SubscribeButton = styled(Button)`
  background-color: #28a745; /* Green color */
  &:hover {
    background-color: #218838;
  }
`;

const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const UserProfile = () => {
  const { apiStagingUrl } = config;
  const [user, setUser] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem('token');
        // Fetch user profile
        const response = await axios.get(`${apiStagingUrl}/usermanagement/profile/`, {
          headers: { Authorization: `Token ${token}` },
        });
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user profile:', error);
        navigate('/login');
        return; // Stop execution if user profile can't be fetched
      }

      try {
        const token = localStorage.getItem('token');
        // Fetch subscription details
        const subscriptionResponse = await axios.get(
          `${apiStagingUrl}/api/get-subscription-details/`,
          {
            headers: { Authorization: `Token ${token}` },
          }
        );
        setSubscription(subscriptionResponse.data);
      } catch (error) {
        console.error('Error fetching subscription details:', error);
        // Handle error but don't redirect
        setSubscription(null);
      }

      try {
        const token = localStorage.getItem('token');
        // Fetch invoices
        const invoicesResponse = await axios.get(
          `${apiStagingUrl}/api/get-invoices/`,
          {
            headers: { Authorization: `Token ${token}` },
          }
        );
        setInvoices(invoicesResponse.data.invoices);
      } catch (error) {
        console.error('Error fetching invoices:', error);
        // Handle error but don't redirect
        setInvoices([]);
      } finally {
        setLoading(false);
      }
    };
    fetchUserProfile();
  }, [navigate, apiStagingUrl]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleCancelSubscription = async () => {
    setCancelLoading(true);
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${apiStagingUrl}/api/cancel-subscription/`,
        {},
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      // Refresh subscription details
      const subscriptionResponse = await axios.get(
        `${apiStagingUrl}/api/get-subscription-details/`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      setSubscription(subscriptionResponse.data);
      setShowCancelModal(false);
    } catch (error) {
      console.error('Error canceling subscription:', error);
      alert('Failed to cancel subscription. Please try again later.');
    } finally {
      setCancelLoading(false);
    }
  };

  const navigateToSubscription = () => {
    navigate('/subscription'); // Ensure you have a route for this page
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <ProfileContainer>
      <Helmet>
        <title>User Profile | Market Catalysts</title>
        <meta
          name="description"
          content="View and manage your Market Catalysts user profile and subscription."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <ProfileCard>
        <ProfileHeader>
          <FaUser /> User Profile
        </ProfileHeader>
        <ProfileInfo>
          <FaUser /> Username: {user.username}
        </ProfileInfo>
        <ProfileInfo>
          <FaEnvelope /> Email: {user.email}
        </ProfileInfo>
        {/* Add first name and last name if available */}
        {user.first_name && (
          <ProfileInfo>
            First Name: {user.first_name}
          </ProfileInfo>
        )}
        {user.last_name && (
          <ProfileInfo>
            Last Name: {user.last_name}
          </ProfileInfo>
        )}
      </ProfileCard>

      <ProfileCard>
        <ProfileHeader>
          <FaCreditCard /> Subscription Details
        </ProfileHeader>
        {subscription && !subscription.error ? (
          <>
            <ProfileInfo>Plan: {subscription.plan_name || 'N/A'}</ProfileInfo>
            <ProfileInfo>
              Original Price: ${subscription.plan_price ? subscription.plan_price.toFixed(2) : 'N/A'}
            </ProfileInfo>

            {/* Current Discount and Amount Due */}
            {subscription.discount_amount !== undefined && subscription.discount_amount > 0 ? (
              <ProfileInfo>
                Current Discount: -${subscription.discount_amount.toFixed(2)} (Coupon: {subscription.coupon})
              </ProfileInfo>
            ) : null}

            <ProfileInfo>
              Amount Paid: {subscription.amount_due !== undefined && subscription.amount_due !== null
                ? `$${subscription.amount_due.toFixed(2)}`
                : 'N/A'}
            </ProfileInfo>

            {/* Next Invoice Amount and Discount */}
            {subscription.next_invoice_amount !== null && subscription.next_invoice_amount !== undefined ? (
              <>
                {subscription.next_discount_amount !== undefined && subscription.next_discount_amount > 0 ? (
                  <ProfileInfo>
                    Next Discount: -${subscription.next_discount_amount.toFixed(2)} (Coupon: {subscription.next_coupon})
                  </ProfileInfo>
                ) : null}
                <ProfileInfo>
                  Next Invoice Amount: ${subscription.next_invoice_amount.toFixed(2)}
                </ProfileInfo>
              </>
            ) : null}

            <ProfileInfo>Status: {subscription.status}</ProfileInfo>
            <ProfileInfo>
              Start Date: {new Date(subscription.start_date).toLocaleDateString()}
            </ProfileInfo>
            <ProfileInfo>
              Next Billing Date: {new Date(subscription.next_payment).toLocaleDateString()}
            </ProfileInfo>
            <ProfileInfo>Payment Method: {subscription.payment_method}</ProfileInfo>
            <ProfileInfo>
              Payments Completed: {subscription.payments_completed || 0}
            </ProfileInfo>
            <ProfileInfo>
              Last Payment Date: {subscription.last_payment ? new Date(subscription.last_payment).toLocaleDateString() : 'N/A'}
            </ProfileInfo>
            {subscription.previous_payment_date && (
              <ProfileInfo>
                Previous Payment Date: {new Date(subscription.previous_payment_date).toLocaleDateString()}
              </ProfileInfo>
            )}
            {subscription.cancel_at_period_end ? (
              <ProfileInfo>
                Your subscription will be canceled at the end of the current billing period.
              </ProfileInfo>
            ) : (
              <CancelButton onClick={() => setShowCancelModal(true)} disabled={cancelLoading}>
                {cancelLoading ? 'Processing...' : 'Cancel Subscription'}
              </CancelButton>
            )}
          </>
        ) : (
          <>
            <ProfileInfo>No active subscription found.</ProfileInfo>
            {/* Subscribe Now button */}
            <SubscribeButton onClick={navigateToSubscription}>
              Subscribe Now
            </SubscribeButton>
          </>
        )}
      </ProfileCard>

      {/* Invoices Section */}
      {invoices.length > 0 && (
        <ProfileCard>
          <ProfileHeader>
            <FaCreditCard /> Payment History
          </ProfileHeader>
          {invoices.map((invoice) => (
            <ProfileInfo key={invoice.id}>
              Date: {new Date(invoice.date).toLocaleDateString()} - Amount: $
              {invoice.amount_due.toFixed(2)} - Status: {invoice.status}
            </ProfileInfo>
          ))}
        </ProfileCard>
      )}

      {/* Cancel Subscription Confirmation Modal */}
      <ReactModal
        isOpen={showCancelModal}
        onRequestClose={() => setShowCancelModal(false)}
        contentLabel="Confirm Cancellation"
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '500px',
            padding: '20px',
            borderRadius: '8px',
          },
        }}
      >
        <h2>Confirm Cancellation</h2>
        <p>Are you sure you want to cancel your subscription?</p>
        <CancelButton onClick={handleCancelSubscription} disabled={cancelLoading}>
          {cancelLoading ? 'Processing...' : 'Yes, Cancel Subscription'}
        </CancelButton>
        <Button onClick={() => setShowCancelModal(false)}>No, Keep Subscription</Button>
      </ReactModal>

      <Button onClick={handleLogout}>
        <FaSignOutAlt /> Logout
      </Button>
    </ProfileContainer>
  );
};

export default UserProfile;
