import React from 'react';
import axios from 'axios';
import config from '../../config/config';

const CartPage = () => {
  const { apiStagingUrl } = config;

  const cartItems = [
    {
      id: 1,
      name: 'Monthly Subscription',
      price: 2000,
      description: '$20.00 per month',
    },
  ];

  const handleCheckout = async () => {
    try {
      const response = await axios.post(`${apiStagingUrl}/create-checkout-session/`, { cartItems });
      console.log('Server response:', response.data);
      if (response.data && response.data.url) {
        window.location.href = response.data.url;
      } else {
        console.error('The checkout session URL is missing.');
        alert('Error: Could not proceed to checkout. Please try again.');
      }
    } catch (error) {
      console.error('There was an error:', error);
      alert('An error occurred during checkout. Please try again.');
    }
  };

  if (cartItems.length === 0) {
    return (
      <div className="container mx-auto">
        <h1 className="text-2xl font-bold mb-4">Cart</h1>
        <p>Your cart is empty.</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto">
      <h1 className="text-2xl font-bold mb-4">Cart</h1>
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        {cartItems.map((item) => (
          <div key={item.id} className="mb-4">
            <h2 className="text-xl font-bold">{item.name}</h2>
            <p className="text-gray-700">{item.description}</p>
          </div>
        ))}
        <button
          onClick={handleCheckout}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Proceed to Checkout
        </button>
      </div>
    </div>
  );
};

export default CartPage;
